import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const PageLoader = () => {
    return (
        <div className={'d-flex justify-content-center align-items-center'} style={{
            width: "100vw",
            height: "100vh"
        }}>
            <ClipLoader size={100} color="#ED2F59" aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    )
}



export default PageLoader