import '../assets/css/sidesocial.css';
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {Image} from "react-bootstrap";

export const SideSocial = () => {

    const render = () => {
        return (
            <div className={`d-none d-xl-block`}
                 style={{
                     position: 'fixed',
                     left: 'auto',
                     right: '2em',
                     marginTop: '25vh',
                     textAlign: 'right',
                     width: '50px',
                     height: 'auto',
                     zIndex: 1
                 }}>
                <div className={'mb-4 sora text-uppercase font-400 font-size-16'} style={{rotate: '270deg'}}>
                    codestudiopak
                </div>
                <div className={`justify-content-center text-center btn-violet-outline d-flex flex-column`}
                     style={{
                         height: '50px',
                         width: '50px',
                     }}
                     onClick={() => {
                         window.open('https://fb.com/codestudiopak', '_blank');
                     }}>
                    <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                </div>
                <div style={{width: '1em', borderBottom: '2px solid #7A28CB', marginTop: '10px', marginBottom: '10px', marginLeft: '1em'}}/>
                <div className={`justify-content-center text-center btn-violet-outline d-flex flex-column`}
                     style={{
                         height: '50px',
                         width: '50px',
                     }}
                     onClick={() => {
                         window.open('https://instagram.com/codestudiopak', '_blank');
                     }}>
                    <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                </div>
                <div style={{width: '1em', borderBottom: '2px solid #7A28CB', marginTop: '10px', marginBottom: '10px', marginLeft: '1em'}}/>
                <div className={`justify-content-center text-center btn-violet-outline d-flex flex-column`}
                     style={{
                         height: '50px',
                         width: '50px',
                     }}
                     onClick={() => {
                         window.open('https://twitter.com/codestudiopak', '_blank');
                     }}>
                    <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                </div>
                <div style={{width: '1em', borderBottom: '2px solid #7A28CB', marginTop: '10px', marginBottom: '10px', marginLeft: '1em'}}/>
                <div className={`justify-content-center text-center btn-violet-outline d-flex flex-column`}
                     style={{
                         height: '50px',
                         width: '50px',
                     }}
                     onClick={() => {
                         window.open('https://linkedin.com/company/codestudiopak', '_blank');
                     }}>
                    <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                </div>
                <div style={{width: '1em', borderBottom: '2px solid #7A28CB', marginTop: '10px', marginBottom: '10px', marginLeft: '1em'}}/>
                <div className={`justify-content-center text-center btn-violet-outline d-flex flex-column`}
                     style={{
                         height: '50px',
                         width: '50px',
                     }}
                     onClick={() => {
                         window.open('https://www.designrush.com/agency/profile/code-studio-pvt-ltd', '_blank');
                     }}>
                    <Image src={require('../assets/images/design_rush.png')} className={'img-thumbnail'} />
                </div>
            </div>
        );
    }

    return render();
}
