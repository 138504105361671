import './../assets/css/index.css';
import {BannerComponent} from "../Components/Portfolio/BannerComponent";
import {CategoriesComponent} from "../Components/Portfolio/CategoriesComponent";
import {GetQuoteButton} from "../Components/GetQuoteButton";

export const Portfolio = () => {
    return (
        <div className={`col`}>
            <BannerComponent/>
            <CategoriesComponent/>
            <div className={`container mt-5 pt-5 mb-5 pb-5 text-center`}>
                <div className={`text-center bebas font-500 font-size-40 text-decoration-underline`}>
                    Let's build something great together
                </div>
                <div className={`sora font-500 font-size-14 text-center mt-4 pt-4 p-lr-20`}>
                    Nullam vitae purus at tortor mattis dapibus. Morbi purus est, ultricies nec dolor sit amet, scelerisque cursus purus.
                </div>
                <div className={`mt-4`}>
                    <GetQuoteButton/>
                </div>
            </div>
        </div>
    )
}