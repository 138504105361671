import React from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";

const ItemLoader = (props) => {

    return props.loading ? (
        <div className={`position-relative`} style={{top: '20px', marginLeft: '50%'}}>
            <ClipLoader size={100} color="#ED2F59" aria-label="Loading Spinner"
                        data-testid="loader"
            />
        </div>
    ) : null;

}

ItemLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
    small: PropTypes.bool
}

export default ItemLoader;
