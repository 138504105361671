import './../assets/css/index.css';
import { BannerComponent } from "../Components/PortfolioSingle/BannerComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import { PortfolioSingleApi } from "../Axios/EndPoints";
import { withRouter } from "../CustomFunctions/withRouter";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { GetQuoteButton } from "../Components/GetQuoteButton";
import PageLoader from '../Components/Loaders/PageLoader';


const PortfolioSingle = (props) => {

    const [portfolioItem, setPortfolioItem] = useState(null);
    const [loading, setLoading] = useState(true)

    const fetchPortfolioItem = () => {
        axios.get(PortfolioSingleApi + props.match.params.id)
            .then((response) => {
                setPortfolioItem(response.data.response);
                setLoading(false)
            })

    }

    useEffect(() => {
        fetchPortfolioItem();
    }, []);

    const renderImages = (item) => {
        let images = [];

        item.images.map((image) => {
            images.push(
                {
                    original: image,
                    thumbnail: image,
                },
            );
        });

        return (
            <>
                <ImageGallery items={images} />
            </>
        );
    }

    const render = () => {
        return (
            <>
                {
                    loading && <PageLoader />
                }
                {
                    !loading &&
                    (
                        <div className={`col`}>
                            <BannerComponent portfolio={portfolioItem} />
                            <div className={`container pb-5`}>
                                <div className={`row`}>
                                    <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                        <span className={`sora font-400`}>Client</span>
                                        <br />
                                        <span className={`sora font-800`}>{portfolioItem.name}</span>
                                    </div>
                                    <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                        <span className={`sora font-400`}>Services & Deliverables</span>
                                        <br />
                                        <span className={`sora font-800`}>{portfolioItem.categories.toString()}</span>
                                    </div>
                                </div>
                                <div className={'mt-4'}>
                                    <span className={'font-size-50 bebas font-600'}>About The Project</span>
                                    <br />
                                    <span className={'font-size-18 sora'}>{portfolioItem.long_description}</span>
                                </div>
                                <div className={`sora font-400 mt-5 pb-5 container`}>
                                    {renderImages(portfolioItem)}
                                </div>

                                {/*<div className={'mt-4'}>
                                    <span className={'font-size-50 bebas font-600'}>How We Do It</span>
                                    <br />
                                    <span className={'font-size-18 sora'}>Since the inception of Code Studio, our team has finished various projects from scratch related to web and mobile applications from various spheres of life. Fanzine follows a similar approach which was made using the latest technology stack to offer the best user experience without any interruptions.</span>

                                    <ul className={'mt-3'}>
                                        <li className={'pb-2'}>LAMP Stack</li>
                                        <li className={'pb-2'}>React-Bootstrap</li>
                                        <li className={'pb-2'}>MongoDB</li>
                                        <li>Redis</li>
                                    </ul>
                                </div>*/}
                            </div>
                            <div className={`container mt-5 pt-5 mb-5 pb-5 text-center`}>
                                <div className={`text-center bebas font-800 font-size-40 text-decoration-underline`}>
                                    Let's build something great together.
                                </div>
                                <div className={`sora font-500 font-size-16 text-center mt-4 pt-4`}>
                                    Reach Out to Us And Let's Discuss Your Dream Project!
                                </div>
                                <div className={`mt-4`}>
                                    <GetQuoteButton />
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        )
    }

    return (
        portfolioItem ? render() : <PageLoader />
    );
}

export default withRouter(PortfolioSingle);
