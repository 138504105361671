import {GetQuoteButton} from "../GetQuoteButton";

export const BannerComponent = () => {
    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <div className={'banner-text'}>
                    <div className={'col p-lr-20'}>
                        <div style={{height: '5em', borderLeft: '5px solid #ED2F59', marginLeft: '50%', marginBottom: '20px'}}/>
                        <div className={'text-center bebas font-500 font-size-70 text-pink'} style={{marginBottom: '-45px'}}>
                            PLAN
                        </div>
                        <div className={'text-center bebas font-500 font-size-90 text-xiketic'} style={{marginBottom: '-45px'}}>
                            CREATE
                        </div>
                        <div className={'text-center bebas font-500 font-size-70 text-pink'}>
                            SHARE
                        </div>
                        <div className={'offset-xl-3 offset-lg-3 offset-md-3 offset-sm-2 col-xl-6 col-lg-6 col-md-6 col-sm-8 text-center sora font-500 font-size-20 text-xiketic'}>
                            We are a Web Design, Development and E-Commerce company which strives to provide the perfect design, development and e-marketing solution for your business needs!
                        </div>
                        <div className={'offset-4 col-4 text-center mt-4'}>
                            <GetQuoteButton/>
                        </div>
                        <div style={{height: '6em', borderLeft: '5px solid #7A28CB', marginLeft: '50%', marginTop: '30px'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}