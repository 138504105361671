import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";

export const TeamComponent = () => {
    return (
        <div className={`container mt-5 mb-5 pt-5 pb-5 p-lr-20`}>
            <div className={`col text-center bebas font-500 font-size-30 text-decoration-underline`}>
                Meet Our Team
            </div>
            <div className={`row mt-5`}>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 text-center pt-5`}>
                    <figure>
                        <img src={require('../../assets/images/resized/Junaid.png')} alt={'Code Studio CEO Muhammad Junaid Naeem'} className={`img-fluid img-circle`}/>
                        <figcaption className={`mt-3`}>
                            <div className={`sora font-600 font-size-16`}>Muhammad Junaid Naeem</div>
                            <div className={`sora font-400 font-size-12`}>Chief Executive Officer</div>
                            <div className={`row mt-4`}>
                                <div className={'col'}></div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://fb.com/codestudiopak', '_blank');
                                     }}>
                                    <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://instagram.com/codestudiopak', '_blank');
                                     }}>
                                    <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://twitter.com/codestudiopak', '_blank');
                                     }}>
                                    <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://linkedin.com/company/codestudiopak', '_blank');
                                     }}>
                                    <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={'col'}></div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 text-center pt-5`}>
                    <figure>
                        <img src={require('../../assets/images/resized/Nazish.png')} alt={'Code Studio CXO Nazish Junaid'} className={`img-fluid img-circle`}/>
                        <figcaption className={`mt-3`}>
                            <div className={`sora font-600 font-size-16`}>Nazish Junaid</div>
                            <div className={`sora font-400 font-size-12`}>Chief Technical Officer</div>
                            <div className={`row mt-4`}>
                                <div className={'col'}></div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://fb.com/codestudiopak', '_blank');
                                     }}>
                                    <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://instagram.com/codestudiopak', '_blank');
                                     }}>
                                    <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://twitter.com/codestudiopak', '_blank');
                                     }}>
                                    <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://linkedin.com/company/codestudiopak', '_blank');
                                     }}>
                                    <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={'col'}></div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 text-center pt-5`}>
                    <figure>
                        <img src={require('../../assets/images/CDO.png')} alt={'Code Studio CDO Shoaib Ahmed'} className={`img-fluid img-circle`}/>
                        <figcaption className={`mt-3`}>
                            <div className={`sora font-600 font-size-16`}>Shoaib Ahmed</div>
                            <div className={`sora font-400 font-size-12`}>Chief Design Officer</div>
                            <div className={`row mt-4`}>
                                <div className={'col'}></div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://fb.com/codestudiopak', '_blank');
                                     }}>
                                    <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://instagram.com/codestudiopak', '_blank');
                                     }}>
                                    <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://twitter.com/codestudiopak', '_blank');
                                     }}>
                                    <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://linkedin.com/company/codestudiopak', '_blank');
                                     }}>
                                    <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={'col'}></div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-6 text-center pt-5`}>
                    <figure>
                        <img src={require('../../assets/images/Talha.png')} alt={'Code Studio TL Talha Javaid Mughal'} className={`img-fluid img-circle`}/>
                        <figcaption className={`mt-3`}>
                            <div className={`sora font-600 font-size-16`}>Talha Javaid</div>
                            <div className={`sora font-400 font-size-12`}>Technical Lead</div>
                            <div className={`row mt-4`}>
                                <div className={'col'}></div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://fb.com/codestudiopak', '_blank');
                                     }}>
                                    <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://instagram.com/codestudiopak', '_blank');
                                     }}>
                                    <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://twitter.com/codestudiopak', '_blank');
                                     }}>
                                    <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={`justify-content-center text-center btn-violet-outline p-0 ms-4 d-flex flex-column`}
                                     style={{
                                         height: '30px',
                                         width: '30px',
                                     }}
                                     onClick={() => {
                                         window.open('https://linkedin.com/company/codestudiopak', '_blank');
                                     }}>
                                    <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-16`}/>
                                </div>
                                <div className={'col'}></div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    )
}
