import './../assets/css/index.css';
import {BannerComponent} from "../Components/Home/BannerComponent";
import {AboutComponent} from "../Components/Home/AboutComponent";
import {ServicesComponent} from "../Components/Home/ServicesComponent";
import {TeamComponent} from "../Components/Home/TeamComponent";
import {TestimonialsComponent} from "../Components/Home/TestimonialsComponent";
import {GetQuoteComponent} from "../Components/Home/GetQuoteComponent";

export const Home = () => {
    return (
        <div className={`col`}>
            <BannerComponent/>
            <AboutComponent/>
            <div className={`bg-light-pink pb-5`}>
                <ServicesComponent/>
            </div>
            <TeamComponent/>
            <div className={`bg-light-pink pt-5 pb-5`}>
                <TestimonialsComponent/>
            </div>
            <GetQuoteComponent/>
        </div>
    )
}