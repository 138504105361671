import "../../assets/css/packages.css";
import Gold from "../../assets/images/Gold.png";
import PackageName from "./PackageName";
import PinkCorner from "./PinkCorner";
import NumberOfPackagePoints from "./NumberOfPackagePoints";
import PackagePrice from "./PackagePrice";
import Bronze from "../../assets/images/Bronze.png";

const ThirdPackage = (props) => {
  const { isMobile, darkBg } = props;

  const mobileView = () => {
    return (
      <div
        className={
          "d-flex flex-column position-relative h-100 justify-content-between"
        }
      >
        <div className={`d-flex flex-column ${isMobile ? "mt-3" : null}`}>
          {!isMobile ? (
            <PackageName
              isMobile={isMobile}
              packageName={"GOLD"}
              imgName={Gold}
            />
          ) : null}

          <div className={"d-flex align-items-center"}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Photo Posts Per Week"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Stories Per Week"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"6 Reels Per Month"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"1 Videos Per Month"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Restaurant Listing Websites"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"2 Facebook Ad Campaigns Per Month"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Facebook Account Setup"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Instagram Account Setup"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Facebook Ads Account Setup"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Google Business Profile Setup"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Social Media Audit (Basic)"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Account Manager (Shared)"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Weekly Report"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Support Within 24 Hours"}
            />
            <PinkCorner thirdPackage={true} isMobile={isMobile} />
          </div>
          <hr
            className={`customHR first-hr-gradient ms-4`}
            style={{ width: "90%" }}
          />
        </div>
        <div className={`${isMobile ? "mt-5" : null}`}>
          <PackagePrice
            isMobile={isMobile}
            oldPrice={"£1,000"}
            newPrice={"849"}
          />
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div
        className={
          "d-flex flex-column position-relative h-100 justify-content-between"
        }
      >
        <div className={"d-flex flex-column"}>
          <PackageName
            isMobile={isMobile}
            packageName={"GOLD"}
            imgName={Gold}
          />

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"10 Photo Posts Per Week"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"10 Stories Per Week"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"20 Reels Per Month"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"5 Videos Per Month"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"15 Restaurant Listing Websites"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"10 Facebook Ad Campaigns Per Month"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Facebook Account Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Instagram Account Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Facebook Ads Account Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Facebook Shop & Pixel Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Instagram Shop Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Google Merchant Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Twitter Shop Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Google Business Profile Setup"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Social Media Audit - Advanced"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Account Manager - Dedicated"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Weekly Report"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Priority Support 24/7"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              isMobile={isMobile}
              point={"- Wordpress Web Maintenance"}
              thirdPackage={true}
            />
            <PinkCorner thirdPackage={true} />
          </div>

          <hr
            className={`customHR third-hr-gradient ms-4`}
            style={{ width: "90%" }}
          />
        </div>
        <div className={"mt-5"}>
          <PackagePrice
            isMobile={isMobile}
            oldPrice={"£1,000"}
            newPrice={"849"}
            packageName={"GOLD"}
          />
        </div>
      </div>
    );
  };

  return isMobile ? mobileView() : renderMain();
};

export default ThirdPackage;
