import GoogleAd from "../GoogleAd";
import React, {useEffect, useState} from "react";
import NewsNavigationVideos from "./NewsNavigationVideos";
import VideoCard from "./VideoCard";
import "../../assets/css/videos.css"

const AllCategoryVideos = (props) => {
    const {isMobile, latestNews} = props;
    const [backgroundSize,setBackGroundSize] = useState('1050px');

    useEffect(() => {
        if (window.screen.width >= 2550) {
            setBackGroundSize('1050px')
        } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
            setBackGroundSize('1050px')
        }
    }, [window.screen.width]);

    const renderAllNews = () => {
        return (<section className={'container mt-3'}>
            <h1 className={'text-pink bebas'}>Latest Videos</h1>
            <div className={`row justify-content-between`}>
                <div className={"col-sm-12 col-xs-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9"}>
                    <VideoCard
                      news={latestNews[1]}
                      isLargeImage={true}
                      isNewsOverImage={true}
                      bgColor={'bg-pink'}
                      height={isMobile ? "214px" : "400px"}
                      fontSize={16}
                    />
                    <div className={"row justify-content-between"}>
                        <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3"}>
                            <VideoCard
                              news={latestNews[2]}
                              isLargeImage={false}
                              isNewsOverImage={true}
                              bgColor={'bg-pink'}
                              fontSize={12}
                              height={isMobile ? "214px" : "13vw"}
                              latestSmall={true}
                            />
                        </div>
                        <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4  mt-3 "}>
                            <VideoCard
                              news={latestNews[3]}
                              isLargeImage={false}
                              isNewsOverImage={true}
                              bgColor={'bg-pink'}
                              fontSize={12}
                              latestSmall={true}
                              height={isMobile ? "214px" : "13vw"}
                            />
                        </div>
                        <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3 "}>
                            <VideoCard
                              news={latestNews[4]}
                              isLargeImage={false}
                              isNewsOverImage={true}
                              bgColor={'bg-pink'}
                              fontSize={12}
                              height={isMobile ? "214px" : "13vw"}
                              latestSmall={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
                    <GoogleAd id={'9462835128'} minHeight={'50px'}/>
                    {/*<VideoCard
                        news={latestNews[6]}
                        isLargeImage={false}
                        isNewsOverImage={true}
                        bgColor={'bg-pink'}
                        fontSize={12}
                        height={isMobile ? "214px" : "558px"}
                        allCategoryVerticalImage={true}
                    />*/}
                </div>
            </div>
        </section>)
    }

    const renderAllCategoryNewsAtTop = () => {
        return (
          <div className={`w-100 bg-light-pink-gradient`}>
              <NewsNavigationVideos bg={'light'}/>
              {/*<NewsNavigation bg={'light'}/>*/}
              {/*<BreakingNewsVideos  news={latestNews[0] || null}/>*/}
              {isMobile && <div className={'col-12'} style={{height: '50px'}}><GoogleAd id={'9462835128'} minHeight={'50px'}/></div>}
              <div className={`${isMobile ? "" : "container-fluid"} bg-light-pink-gradient`}>
                  {latestNews.length ? renderAllNews() : <></>}
              </div>
          </div>
        )
    }

    const renderMain = () => renderAllCategoryNewsAtTop()

    return renderMain()
}

export default AllCategoryVideos;
