import {useEffect, useState} from "react";
import {FaAngleLeft, FaAngleRight, FaQuoteLeft} from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import {BsChatLeftQuoteFill} from "react-icons/bs";

export const TestimonialsComponent = () => {

    const [testimonials, setTestimonials] = useState([]);
    const [currentTestimonial, setCurrentTestimonial] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    const minIndex = 0;

    const fetchTestimonials = () => {
        let tempData = [];
        axios
            .get("https://codestudio.solutions/api/v1/testimonials")
            .then((res) => {
                setTestimonials(res.data.response);
                setCurrentTestimonial(res.data.response[0]);
                setMaxIndex(res.data.response.length - 1);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            maxIndex > 0 && handleNextClick(false, true);
        }, 30000);

        return () => clearInterval(interval);
    }, [maxIndex]);

    const initialize = async () => {
        await fetchTestimonials();
    }

    const handleNextClick = (e = false, restart = false) => {
        setCurrentIndex(prevState => {
            let newState = prevState + 1;
            if (prevState >= maxIndex) {
                newState = restart ? minIndex : maxIndex;
            }
            setCurrentTestimonial(testimonials[newState]);
            return newState;
        })
    }
    const handleLastClick = () => {
        let tempCurrentIndex = currentIndex;
        let newCurrentIndex = --tempCurrentIndex;
        if (newCurrentIndex <= minIndex) {
            newCurrentIndex = minIndex;
        }
        setCurrentIndex(newCurrentIndex);
        setCurrentTestimonial(testimonials[newCurrentIndex]);
    }

    const renderTestimonial = () => {
        return (
            <div>
                <div className={`sora text-xiketic font-500 font-size-16 text-start`}>
                    "{currentTestimonial.testimony}"
                </div>
                <div className={`row mt-5`}>
                    <div className={`col-4 text-start`}>
                        <img src={currentTestimonial.client_picture} alt={currentTestimonial.client_company} height={'75'}/>
                        <span className={'d-inline-flex flex-column align-middle ms-3'}>
                            <div className={'sfProDisplayFont'}>{currentTestimonial.client_name}</div>
                            <div className={'sfProDisplayFont'}>{currentTestimonial.client_company}</div>
                        </span>
                    </div>
                    <div className={'col mb-0 mt-auto'}>
                        <button className={`btn ${currentIndex === minIndex ? 'disabled border-0' : 'btn-violet'} rounded-circle pt-2 pb-2`} onClick={handleLastClick}>
                            <FaAngleLeft size={20}/>
                        </button>
                        &nbsp;&nbsp;
                        <button className={`btn ${currentIndex === maxIndex ? 'disabled border-0' : 'btn-violet'} rounded-circle pt-2 pb-2`} onClick={handleNextClick}>
                            <FaAngleRight size={20}/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const renderLoadingSkeleton = () => {
        return (
            <>
                <Skeleton count={1} height={'75px'} width={'100%'} className={'ms-2'} baseColor={'#7A28CB'} highlightColor={'#ED2F59'}/>
                <Skeleton count={2} height={'50px'} width={'50px'}
                          baseColor={'#7A28CB'} highlightColor={'#ED2F59'}
                          containerClassName={'mt-4 d-flex flex-row justify-content-between'} circle={true} inline={true}/>
            </>
        );
    }

    const render = () => {
        return (
            <div className={`container mt-5 mb-5 pt-5 pb-5 p-lr-20`}>
                <div className={`row mb-5`}>
                    <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-start mb-4`}>
                    </div>
                    <div className={`offset-xl-1 offset-lg-1 offset-md-1 offset-sm-0 col-xl-8 col-lg-8 col-md-8 col-sm-12 text-start`}>
                        <FaQuoteLeft className={'text-violet font-size-30'} />
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-start mb-4`}>
                        <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                            What our clients say about us
                        </div>
                        <div className={`sora font-size-14 d-none d-md-block d-lg-block d-xxl-block`}>
                            <br/>
                            We develop exceptional websites and digital products for you. We work with you to create a design that’s unique and tailored to your needs. See what clients like yourself
                            have to say about our work.
                        </div>
                    </div>
                    <div className={`offset-xl-1 offset-lg-1 offset-md-1 offset-sm-0 col-xl-8 col-lg-8 col-md-8 col-sm-12 text-end`}>
                        {
                            testimonials ?
                                currentTestimonial && renderTestimonial() :
                                renderLoadingSkeleton()
                        }
                    </div>
                </div>
            </div>
        );
    }

    return render();
}