import {GetQuoteButton} from "../GetQuoteButton";

export const BannerComponent = () => {
    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <div className={'banner-text'}>
                    <div className={'col p-lr-20'}>
                        <div style={{height: '5em', borderLeft: '5px solid #ED2F59', marginLeft: '50%', marginBottom: '20px'}}/>
                        <div className={'text-center bebas font-500 font-size-70 text-pink'} style={{marginBottom: '-45px'}}>
                            Our
                        </div>
                        <div className={'text-center bebas font-500 font-size-90 text-xiketic'} style={{marginBottom: '-45px'}}>
                            Work
                        </div>
                        <div className={'text-center bebas font-500 font-size-70 text-pink'}>
                            Portfolio
                        </div>
                        <div className={'offset-xl-4 offset-lg-4 offset-md-4 offset-sm-2 col-xl-4 col-lg-4 col-md-4 col-sm-8 text-center sora font-500 font-size-20 text-xiketic'}>
                            We help teams create great digital products by providing them with tools and technology to make the design-to-code process universally accessible.
                        </div>
                        <div className={'offset-xl-4 offset-lg-4 offset-md-4 offset-sm-3 col-4 text-center mt-4'}>
                            <GetQuoteButton/>
                        </div>
                        <div style={{height: '6em', borderLeft: '5px solid #7A28CB', marginLeft: '50%', marginTop: '30px'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}