import React from 'react'
import banner from '../assets/images/banner.jpg'
import BlogFeaturedPost from '../Components/Blog/BlogFeaturedPost'
import BlogPosts from '../Components/Blog/BlogPosts'
import WriteBlogs from '../Components/Blog/WriteBlogs'

const Blog = () => {
    return (
        <>
            <div style={{
                marginTop: "-75px",
            }}>
                <div style={{
                    backgroundImage: `url(${banner})`,
                }}>
                    <BlogFeaturedPost />
                </div>
                <BlogPosts/>
                <WriteBlogs/>
            </div>
        </>
    )
}

export default Blog