import VideoCard from "./VideoCard";
import React from "react";
import GoogleAd from "../GoogleAd";

const FeaturedVideos = (props) =>{
	const {latestNews, isMobile} = props;
	const renderMain = () =>{
		return(
			<>
				<div className={"container bg-white mb-5"}>
					<div className={"row mt-5 mb-3 justify-content-between align-items-center"}>
						<div className={"col"}>
							<h1 className={'text-pink bebas'}>FEATURED Videos</h1>
						</div>
						{
							!isMobile &&
							<div className={"col-1 me-2"}>
								<div className={"row"}>
									<button className={"btn btn-violet rounded-0 nav-link px-3 py-2 align-self-end"}>
										View All
									</button>
								</div>
							</div>
						}

					</div>
					<div className={"row justify-content-between mt-1 mb-5 flex-wrap"}>
						<div className={"col-md-7 col-lg-7 col-xl-7 col-xxl-7"}>
							<VideoCard
								news={latestNews[6]}
								isNewsOverImage={false}
								isLargeImage={true}
								isNewsBelow={true}
								bgColor={'bg-pink'}
								height={isMobile ? "214px": "20vw"}
							/>
						</div>
						<div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
							<div className={"row flex-wrap "}>
								<VideoCard
									news={latestNews[7]}
									isNewsOverImage={false}
									isNewsSide={true}
									fontSize={12}
									height={isMobile ? "214px":"145px"}
								/>
							</div>

							<div className={"row mt-2"}>
								<VideoCard
									news={latestNews[10]}
									isNewsOverImage={false}
									isNewsSide={true}
									fontSize={12}
									height={isMobile ? "214px":"145px"}
								/>
							</div>

							<div className={"row mt-2"}>
								<VideoCard
									news={latestNews[7]}
									isNewsOverImage={false}
									isNewsSide={true}
									fontSize={12}
									height={isMobile ? "214px":"145px"}
								/>
							</div>
						</div>
					</div>
				</div>
				{isMobile && <div className={'col-12'} style={{height: '50px'}}><GoogleAd id={'9462835128'} minHeight={'50px'}/></div>}
				<GoogleAd id={'2122836505'} format={'autorelaxed'}/>
			</>
		)
	}

	return renderMain()
}

export default FeaturedVideos;
