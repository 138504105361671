import './../assets/css/index.css';
import {FormControl, FormLabel} from "react-bootstrap";
import {useEffect, useState} from "react";
import validator from "validator/es";
import axios from "axios";
import {ContactUsAPI} from "../Axios/EndPoints";
import {FaLink} from "react-icons/fa";
import {Multiselect} from "multiselect-react-dropdown";

export const ContactUs = () => {

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [social, setSocial] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedServicesCount, setSelectedServicesCount] = useState(0);
    const [message, setMessage] = useState('');

    const [isPostSuccess, setIsPostSuccess] = useState(null);

    const handleSubmitClick = () => {
        let data = null;

        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }
        if (message === '') {
            canBeSubmitted = false;
            setErrorMessage(true);
        }

        if (canBeSubmitted) {
            let services = [];
            selectedServices.map((service) => {
               services.push(service.name);
            });


            data = {
                name: name,
                email: email,
                phone: phone,
                social_media_profile: services.toString(),
                message: message
            };
            postData(data);
        }
    }

    const postData = (data) => {
        axios.post(ContactUsAPI, data).then((response) => {
            if (response.data.success) {
                setIsPostSuccess('success');
            } else {
                setIsPostSuccess('danger');
            }
        })
    }

    const onSelect = (selectedList, selectedItem) => {
        // console.log('onSelect', selectedList, selectedItem);
        let countServices = selectedList.length;
        setSelectedServices(selectedList);
        setSelectedServicesCount(countServices);
        document.getElementById('search_input').value = `${countServices} Services Selected`;
    }

    const onRemove = (selectedList, removedItem) => {
        // console.log('onRemove', selectedList, removedItem);
        let countServices = selectedList.length;
        setSelectedServices(selectedList);
        setSelectedServicesCount(countServices);
        document.getElementById('search_input').value = `${countServices} Services Selected`;
    }

    useEffect(() => {
    }, [errorName, errorEmail, errorPhone, errorMessage]);

    const render = () => {
        const options = [
            {name: 'Logo Design', id: 'Logo Design'},
            {name: 'Corporate Identity', id: 'Corporate Identity'},
            {name: 'Website Maintenance', id: 'Website Maintenance'},
            {name: 'Website Design', id: 'Website Design'},
            {name: 'Website Development', id: 'Website Development'},
            {name: 'Web Application Development', id: 'Web Application Development'},
            {name: 'Mobile Application Development', id: 'Mobile Application Development'},
            {name: 'Wordpress Website Development', id: 'Wordpress Website Development'},
            {name: 'Social Media Development', id: 'Social Media Development'},
            {name: 'Cloud Architecture Consultancy', id: 'Cloud Architecture Consultancy'},
            {name: 'Cloud Architecture Implementation', id: 'Cloud Architecture Implementation'},
        ];
        return (
            <div className={'banner'}>
                <div className={'banner-image'}>
                    <div className={'banner-text pb-5'}>
                        <div className={'col pb-5'}>
                            <div className={`bebas font-500 font-size-30 text-decoration-underline text-center`}>
                                Contact Us
                            </div>
                            <div className={`sora font-500 font-size-16 text-center mt-4`}>
                                Reach Out to Us And Let's Discuss Your Dream Project!
                            </div>
                        </div>
                        <div className={`container mt-5 contact-us-container`}>
                            <div className={`container bg-white border rounded-4 p-5 contact-us-box`}>
                                <div className={`row`}>
                                    <div className={`col-xl-8 col-lg-8 col-md-8 col-sm-12`}>
                                        <div className={`row`}>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl
                                                    isInvalid={errorName}
                                                    type="text"
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder="Your Name"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={name}
                                                    onChange={(e) => {
                                                        setErrorName(false);
                                                        setName(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input name</FormControl.Feedback>
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl
                                                    isInvalid={errorEmail}
                                                    type="email"
                                                    id="email"
                                                    aria-describedby="email"
                                                    placeholder="Your Email"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setErrorEmail(false);
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid email</FormControl.Feedback>
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                                <FormLabel>Phone Number</FormLabel>
                                                <FormControl
                                                    isInvalid={errorPhone}
                                                    type="text"
                                                    id="phone"
                                                    aria-describedby="phone"
                                                    placeholder="Your Phone Number"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={phone}
                                                    onChange={(e) => {
                                                        setErrorPhone(false);
                                                        setPhone(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid phone number</FormControl.Feedback>
                                            </div>
                                            <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12`}>
                                                <FormLabel>
                                                    Services Required
                                                    <span className={`font-size-10 ms-2 text-pink`}>{selectedServicesCount} Services Selected</span>
                                                </FormLabel>
                                                <Multiselect
                                                    options={options} // Options to display in the dropdown
                                                    onSelect={onSelect} // Function will trigger on select event
                                                    onRemove={onRemove} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    hideSelectedList={true}
                                                    showCheckbox={true}
                                                    showArrow={true}
                                                />
                                                {/*<FormSelect multiple={true}>
                                                    <option>Logo Design</option>
                                                    <option>Corporate Identity</option>
                                                    <option>Website Maintenance</option>
                                                    <option>Website Design</option>
                                                    <option>Website Development</option>
                                                    <option>Web Application Development</option>
                                                    <option>Mobile Application Development</option>
                                                    <option>Wordpress Website Development</option>
                                                    <option>Social Media Development</option>
                                                    <option>Cloud Architecture Consultancy</option>
                                                    <option>Cloud Architecture Implementation</option>
                                                </FormSelect>*/}
                                                {/*<FormControl
                                                    type="url"
                                                    id="social"
                                                    aria-describedby="social"
                                                    placeholder="Social Media Profile ( if any )"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={social}
                                                    onChange={(e) => {
                                                        setSocial(e.target.value);
                                                    }}
                                                />*/}
                                            </div>
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12`}>
                                                <FormLabel>Message</FormLabel>
                                                <FormControl
                                                    isInvalid={errorMessage}
                                                    as={'textarea'}
                                                    rows={6}
                                                    id="message"
                                                    aria-describedby="message"
                                                    placeholder="Your Query"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={message}
                                                    onChange={(e) => {
                                                        setErrorMessage(false);
                                                        setMessage(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input message</FormControl.Feedback>
                                            </div>
                                            <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12`}>
                                                <button className={`btn btn-violet rounded-0 bebas font-600 font-size-20`} onClick={handleSubmitClick}>
                                                    Let's Talk
                                                </button>
                                                {
                                                    isPostSuccess !== null && (
                                                        <div className={`alert alert-${isPostSuccess} alert-dismissible`}>
                                                            {
                                                                isPostSuccess === 'success' ?
                                                                    'Successfully submitted' :
                                                                    'Some error occurred. Please try again later'
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12`}>
                                        <div className={`mt-5 d-block d-xl-none d-lg-none d-md-none`}></div>
                                        <div className={`container`} key={'number'}>
                                            <div className={'sora text-pink'}>
                                                Helpline Number
                                            </div>
                                            <div className={'sora text-xiketic font-size-14'}>
                                                <a href={'tel:+92 348 021 6565'} className={'text-xiketic font-size-14 text-decoration-none'}>
                                                    <FaLink className={`me-3`}/>
                                                    +92 348 021 6565
                                                </a>
                                            </div>
                                        </div>
                                        <div className={`container mt-5`} key={'email'}>
                                            <div className={'sora text-pink'}>
                                                Email
                                            </div>
                                            <div className={'sora text-xiketic font-size-14'}>
                                                <a href={'mailto:info@codestudio.com.pk'} className={'text-xiketic font-size-14 text-decoration-none'}>
                                                    <FaLink className={`me-3`}/>
                                                    info@codestudio.com.pk
                                                </a>
                                            </div>
                                        </div>
                                        <div className={`container mt-5`} key={'address'}>
                                            <div className={'sora text-pink'}>
                                                Address
                                            </div>
                                            <div className={'sora text-xiketic font-size-14'}>
                                                <a href={'https://goo.gl/maps/E8DFP9y5yiLCoJ7V6'} target={"_blank"} className={'text-xiketic font-size-14 text-decoration-none'}>
                                                    <FaLink className={`me-3`}/>
                                                    Office 9-N, Floor 2nd, Alaseel Plaza I-10 Markaz Islamabad, ISB 44000
                                                </a>
                                            </div>
                                        </div>
                                        <div className={`container mt-5`} key={'workingHours'}>
                                            <div className={'sora text-pink'}>
                                                Working Hours
                                            </div>
                                            <div className={'sora text-xiketic font-size-14'}>
                                                <a href={'https://calendly.com/codestudiopk/30min'} target={"_blank"} className={'text-xiketic font-size-14 text-decoration-none'}>
                                                    <FaLink className={`me-3`}/>
                                                    Monday–Friday: 11:00AM–7:00PM
                                                    <br/>
                                                    <span className={`ps-4 ms-1`}>Saturday & Sunday: Closed</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}
