import {FaCircle} from "react-icons/fa";

export const WeAreComponent = () => {
    return (
        <div className={`container mt-5 pb-5 mb-5 p-lr-20`}>
            <div className={`bebas font-500 font-size-40 text-xiketic text-decoration-underline text-center`}>
                We Thrive Ourselves On Creativity, Intuition & Determination
            </div>
            <div className={`container-fluid mt-5  justify-content-end d-flex flex-row pb-5 mb-5`}>
                <div className={`row`}>
                    <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3`}>
                        <div className={'text-violet'}>
                            <FaCircle className={'text-violet'}/>
                            &nbsp;&nbsp;
                            <span className={`opacity-25`}>-----------------------</span>
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            We Are Creative
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            Quality and Creative work delivered to you based on your brand identity.
                        </div>
                    </div>
                    <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3`}>
                        <div className={'text-violet'}>
                            <FaCircle className={'text-violet'}/>
                            &nbsp;&nbsp;
                            <span className={`opacity-25`}>-----------------------</span>
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            We Have Intuition
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            Intuitive Website design for an enriching <br /> user-experience.
                        </div>
                    </div>
                    <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3`}>
                        <div className={'text-violet'}>
                            <FaCircle className={'text-violet'}/>
                            &nbsp;&nbsp;
                            <span className={`opacity-25`}>-----------------------</span>
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            We Are Determined
                        </div>
                        <div className={'sora text-xiketic mt-3'}>
                            Determined to give timely deliverance of results according to your needs.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}