import {AiOutlineCaretRight} from "react-icons/ai";
import purpleButton from "../../assets/images/purpleButton.png"

import React from "react";

const PurpleButton = (props) => {
	const {isMobile} = props;
	const renderMain = () => {
		return(
			<div  style={{zIndex: 400, padding: "0px", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center"}}>
				<img src={purpleButton}  width={"105vw"} alt={"purple button"} />
			</div>
		)
	}

	return renderMain()
}

export default PurpleButton
