import Bronze from "../../assets/images/Bronze.png";

const PackageName = (props) => {
  const { isMobile, packageName, imgName } = props;
  const renderMain = () => {
    return (
      <div
        className={`${isMobile ? null : "head-text"} mt-3 position-relative ${
          isMobile ? "mb-3" : null
        }`}
        style={{
          position: isMobile ? null : "relative",
          bottom: isMobile ? null : "35px",
          left: isMobile ? null : "27px",
        }}
      >
        <div className="head-image justify-content-center d-flex">
          <img src={imgName} alt={`${imgName} package`} className={"w-50"} />
        </div>
        <div
          className="position-absolute text-center w-100"
          style={{
            top: isMobile ? "18%" : "25%",
            bottom: isMobile ? "-20%" : "15%",
          }}
        >
          <span className={"text-white font-900"}>{packageName}</span>
        </div>
      </div>
    );
  };

  return renderMain();
};

export default PackageName;
