import {Card, Image} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import HTMLEllipsis from "react-lines-ellipsis/lib/html.modern.mjs";
import moment from "moment";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {SCREEN_SIZE} from "../../Constants/ScreenSizes";
import {AiOutlineCaretRight} from "react-icons/ai"
import PurpleButton from "./PurpleButton";

const VideoCard = (props) => {
    const {
        news, newsCard, isNewsOverImage, isNewsSide, isNewsBelow, latestSmall, fontSize, latestPost} = props;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    const setDefaultImage = (ev) => {
        ev.target.src = require("../../assets/images/defaultNewsImage.jpg");
    }

    const renderNewsOverImage = () => {
        let className = `${newsCard}Styles`;
        return (
            <Card className={`col border-0 bg-transparent mb-1 w-100 video-overlay `}>
                <div className={'row position-relative '}>
                    <Card.Img
                        src={news.source_image}
                        alt={news.title}
                        className={`${className} card-image-radius-zero img-fluid`}
                        style={{height: props.height || 'auto', objectFit: 'cover'}}
                        onError={setDefaultImage}
                    />
                  <div className={"row align-items-center position-absolute w-100 h-100 justify-content-center video-overlay-side-image"} style={{zIndex: 400, marginLeft: isMobile ? "5px": "4.5px"}}>
                    <PurpleButton isMobile={isMobile}/>
                  </div>
                </div>


                <Card.ImgOverlay
                    className={`d-flex align-items-start rounded-custom cursor-pointer  `}>
                    <Card.Text className={`sora text-white font-size-12 zIndexClass`}
                               style={{zIndex: 600, backgroundColor: news.category?.background_color?.replaceAll('##', '#'), color: news.category?.font_color?.replaceAll('##', '#')}}>
                        <Link to={`/videos/${news.category?.slug}`} role="button" className={`btn btn-sm text-white`}
                              style={{
                                  fontSize:  isMobile ? 12 : fontSize,
                                  backgroundColor: news.category?.background_color?.replaceAll('##', '#'),
                                  color: news.category?.font_color?.replaceAll('##', '#'),

                              }}
                        >
                            <HTMLEllipsis
                                unsafeHTML={news.category?.name || news.slug}
                                maxLine='4'
                                ellipsis='...'
                                trimright={`${isMobile ? "" : "true"}`}
                                style={{backgroundColor: news.category?.background_color, color: news.category?.font_color}}
                            />
                        </Link>
                    </Card.Text>

                </Card.ImgOverlay>
                {
                    isNewsOverImage &&
                    <>
                        <Card.ImgOverlay className={'d-flex align-items-start flex-column justify-content-end'} style={{zIndex: 600}}>
                            <Card.Text className={`sora text-white font-size-10`}>
                                <HTMLEllipsis
                                    unsafeHTML={`${news.publisher?.name || news.title.substring(0, 5)} - ${moment(news.published_at || new Date()).format('D MMM YYYY')}`}
                                    maxLine={`${isMobile ? 20 : 2}`}
                                    ellipsis='...'
                                    trimright={`${isMobile ? "" : "true"}`}
                                />
                            </Card.Text>

                            <Card.Text className={`font-600 sora text-white ${isMobile ? "font-size-12" : "font-size-16"}`}>
                                <HTMLEllipsis
                                    unsafeHTML={`${news.title}`}
                                    maxLine={`${isMobile ? 20 : 2}`}
                                    ellipsis='...'
                                    // trimright={`${isMobile ? mn : "true"}`}
                                    basedOn='words'
                                    style={{fontSize:  isMobile ? 12 : fontSize}}
                                />
                            </Card.Text>
                        </Card.ImgOverlay>
                    </>
                }
                {isNewsBelow && newsBelowImage()}
            </Card>
        )
    }

    const newsBelowImage = () => {
        return (
            <div className={`d-flex flex-column py-3 ${latestPost && "px-2"}`}>
                <span className={`font-700 ${isMobile ? "font-size-14" : "font-size-16"} ${isMobile ? "" : 'mb-1'} mt-2 fontColor sora`}>
                    <HTMLEllipsis
                        unsafeHTML={`${news.title}`}
                        maxLine='9'
                        ellipsis='...'
                        trimright={`${isMobile ? "" : "true"}`}
                        basedOn='letters'
                    />
                </span>
                <span className={`font-size-12 ${isMobile && 'mt-1'} ${isMobile && 'mb-5'} text-grey sora`}>
                    {news.publisher?.name || news.title.substring(0, 5)} - {moment(news.published_at || new Date()).format('D MMM YYYY')}
                </span>
            </div>
        )
    }


    const newsSideImage = () => {
        return (
            <div className={`${isMobile ? "" : "d-flex"} mb-1  ${isMobile ? "" : "ms-2"} col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 `}>
                <div className={`row position-relative ${isMobile ? "" : "w-100"}`}>
                    <Image
                        src={news.source_image}
                        alt={news.title}
                        style={{height: props.height , objectFit: "cover"}}
                        className={"img-fluid"}
                    />
                  <div className={"row align-items-center justify-content-center position-absolute h-100 video-overlay-side-image"} style={{width: isMobile? "94%" : "92%", marginLeft: "12px",
                    zIndex: 700}}>
                    <PurpleButton isMobile={isMobile}/>
                  </div>
                </div>

                <div className={`${isMobile ? "" : "w-50"} d-flex flex-wrap word-break flex-column mb-1 
                 ${isMobile ? "" : "ms-2"} col-sm-12 col-xs-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9`}>
                    <span className={`flex-wrap font-700 ${isMobile ? "font-size-12" : "font-size-14"}  ${isMobile ? "" : 'mb-4'} fontColor sora`}>
                        <HTMLEllipsis
                            unsafeHTML={`${news.title}`}
                            maxLine='3'
                            ellipsis='...'
                            trimright={`${isMobile ? "" : "true"}`}
                        />
                    </span>
                    <span className={`font-size-12 text-grey  ${isMobile && 'mt-1'} ${isMobile && 'mb-1'} sora`}
                          dangerouslySetInnerHTML={{__html: (news.publisher?.name || news.title.substring(0, 5))}}
                    />
                    <span className={`font-size-12 text-grey  ${isMobile && 'mt-1'} ${isMobile && 'mb-3'} sora`}
                          dangerouslySetInnerHTML={{__html: (moment(news.published_at || new Date()).format('D MMM YYYY'))}}
                    />
                </div>
            </div>
        )
    }

    const renderMain = () => {
        return (
            <>
                <Link to={`/videos/${news.category.slug}/${news.slug}`} className={`text-decoration-none ${isNewsOverImage ? 'text-white' : 'text-black'} w-100`}>
                    {
                        isNewsSide ? newsSideImage() : renderNewsOverImage()
                    }
                </Link>
            </>
        )
    }

    return news && renderMain();
}

VideoCard.propTypes = {
    news: PropTypes.object.isRequired,
    // newsCard: PropTypes.string.isRequired,
}

export default VideoCard
