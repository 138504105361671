import './../assets/css/index.css';
import {BannerComponent} from "../Components/AboutUs/BannerComponent";
import {TeamComponent} from "../Components/Home/TeamComponent";
import {WeAreComponent} from "../Components/AboutUs/WeAreComponent";
import {MissionVisionComponent} from "../Components/AboutUs/MissionVisionComponent";
import {BenefitComponent} from "../Components/AboutUs/BenefitComponent";
import {GetQuoteButton} from "../Components/GetQuoteButton";

export const AboutUs = () => {

    const renderRedBox = () => {
        return (
            <div
                className={`offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 col-xl-8 col-lg-8 col-md-8 col-sm-12 d-flex flex-column justify-content-center p-5 text-white sora`}
                style={{
                    backgroundImage: `url("${require('../assets/images/man-in-blue-jacket-looking-at-white-board-7413916.jpg')}")`,
                    height: 'auto',
                    marginTop: '-150px',
                    textAlign: 'justify',
                }}
            >
                At Code Studio, our goal is to turn your imagination into a working software catering to your IT needs as a company or as an individual. In addition to above mentioned
                services, we also provide IT consultancy to help business and individuals better understand the role of IT in today’s world and how it is crucial to help a business grow and flourish.
                <br/>
                <br/>
                We have been working with a number of prestigious clients on project based as well as long term operations and support basis. Few notable names include NTS, CTS, CHIP Consulting, NEEF,
                Neelaamee etc. In addition to client projects, we’re also working on a few products including POS, school management systems, a stock exchange social forum (www.quorex.pk) and a
                charity support website (www.wallofkindness.com.pk).
            </div>
        );
    }

    const render = () => {
        return (
            <div className={`col`}>
                <BannerComponent/>
                <div className={`bg-light-pink pt-5 pb-5`}>
                    <WeAreComponent/>
                </div>
                {renderRedBox()}
                <MissionVisionComponent/>
                <div className={`bg-light-pink-gradient pt-5 pb-5`}>
                    <BenefitComponent/>
                    <TeamComponent/>
                    <div className={'offset-4 col-4 text-center mt-4'}>
                        <GetQuoteButton/>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}
