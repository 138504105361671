import "../node_modules/bootstrap/dist/css/bootstrap.css";
import {Home} from "./Pages/Home";
import {Route, Routes, useLocation} from "react-router-dom";
import {AboutUs} from "./Pages/AboutUs";
import {Services} from "./Pages/Services";
import Navigation from "./Components/Navigation";
import {ContactUs} from "./Pages/ContactUs";
import {SideSocial} from "./Components/SideSocial";
import {Footer} from "./Components/Footer";
import {Portfolio} from "./Pages/Portfolio";
import Blog from "./Pages/Blog";
import {ServicesDetail} from "./Pages/ServicesDetail";
import {PageNotFound} from "./Pages/PageNotFound";
import PortfolioSingle from "./Pages/PortfolioSingle";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import News from "./Pages/News";
import React, {useEffect, useState} from "react";
import NewsSingle from "./Pages/NewsSingle";
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Toast} from "react-bootstrap";
import {getMessaging} from "firebase/messaging";
import {getLocalToken, onMessageListener} from "./firebase";
import moment from "moment";
import Videos from "./Pages/Videos";
import VideoSingle from "./Components/Videos/VideoSingle";
import {getCountryForTimezone} from "countries-and-timezones";
import {post} from "./Axios/post";
import {STORE_FCM_TOKEN} from "./Axios/EndPoints";

function App() {
    const location = useLocation();

    const [show, setShow] = useState(false);
    const [fcmToken, setFcmToken] = useState(null);
    const [notification, setNotification] = useState({
        title: "",
        body: "",
        time: moment(),
    });
    const [isTokenFound, setTokenFound] = useState(false);
    if (!fcmToken) {
        getLocalToken(setTokenFound)
            .then((res) => {
                setFcmToken(res);
            });
    }

    onMessageListener()
        .then((payload) => {
            setShow(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
                time: moment(),
            });
            console.log(payload);
        })
        .catch((err) => console.log("failed: ", err));

    useEffect(() => {
        const firebaseConfig = {
            apiKey: "AIzaSyCe6XJz-f0jTpK_BdKZiHHVhb08K88yxAk",
            authDomain: "codestudio-7b234.firebaseapp.com",
            projectId: "codestudio-7b234",
            storageBucket: "codestudio-7b234.appspot.com",
            messagingSenderId: "482922566468",
            appId: "1:482922566468:web:3b7e3c5557492045b33d11",
            measurementId: "G-BFT9137KBL",
        };
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);

        logEvent(analytics, "notification_received");

        const messaging = getMessaging(app);
    }, []);

    useEffect(() => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezone = getCountryForTimezone(timeZone);
        let body = {
            fcm_token: fcmToken,
            timezone: `${timeZone} - ${timezone?.name || ''}`
        }
        console.log('timezone', timeZone);
        if (fcmToken) {
            post(STORE_FCM_TOKEN, body)
                .then((response) => {
                });
        }
    }, [fcmToken]);

    const renderToast = () => {
        let timeDiff = moment(moment().diff(notification.time)).minutes();
        return (
            <div className="App">
                <Toast
                    onClose={() => setShow(false)}
                    show={show}
                    delay={30000}
                    autohide
                    animation
                    style={{
                        position: "absolute",
                        top: 20,
                        right: 20,
                        zIndex: 100000,
                    }}
                >
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded mr-2"
                            alt=""
                        />
                        <strong className="mr-auto">{notification.title}</strong>
                        <small className={"ms-2"}>{timeDiff} mins ago</small>
                    </Toast.Header>
                    <Toast.Body style={{backgroundColor: "rgba(255, 255, 255, 1)"}}>
                        {notification.body}
                    </Toast.Body>
                </Toast>
            </div>
        );
    };

    const renderMain = () => {
        return (
            <>
                {renderToast()}
                <Navigation bg={"light"}/>
                <SideSocial/>
                <Routes>
                    <Route exact path={"/"} element={<Home/>}/>
                    <Route exact path={"/about-us"} element={<AboutUs/>}/>
                    <Route exact path={"/services"} element={<Services/>}/>
                    <Route exact path={"/services/detail"} element={<ServicesDetail/>}/>
                    <Route exact path={"/news"} element={<News/>}/>
                    <Route exact path={"/news/:category"} element={<News/>}/>
                    <Route exact path={"/news/:category/:slug"} element={<NewsSingle/>}/>
                    <Route exact path={"/videos"} element={<Videos/>}/>
                    <Route exact path={"/videos/:category"} element={<Videos/>}/>
                    <Route exact path={"/videos/:category/:slug"} element={<VideoSingle/>}/>
                    {/*<Route exact path={"/packages"} element={<Packages />} />*/}
                    {/*VideoSingle*/}
                    {/*</Route>*/}
                    <Route exact path={"/contact-us"} element={<ContactUs/>}/>
                    <Route exact path={"/portfolio"} element={<Portfolio/>}/>
                    <Route exact path={"/portfolio/:id"} element={<PortfolioSingle/>}/>
                    <Route exact path={"/blog"} element={<Blog/>}/>
                    <Route exact path={"*"} element={<PageNotFound/>}/>
                </Routes>
                <FloatingWhatsApp
                    phoneNumber={"+923480216565"}
                    accountName={"Code Studio Private Limited"}
                    avatar={require("./assets/images/favicon.png")}
                    chatMessage={"Thank you for contacting. How can we help you? "}
                />
                <Footer/>
            </>
        );
    }

    const renderNews = () => {
        return (
            <>
                {renderToast()}
                <Navigation bg={"light"}/>
                <SideSocial/>
                <Routes>
                    <Route exact path={"/"} element={<News/>}/>
                    <Route exact path={"/news"} element={<News/>}/>
                    <Route exact path={"/news/:category"} element={<News/>}/>
                    <Route exact path={"/news/:category/:slug"} element={<NewsSingle/>}/>
                    <Route exact path={"/videos"} element={<Videos/>}/>
                    <Route exact path={"/videos/:category"} element={<Videos/>}/>
                    <Route exact path={"/videos/:category/:slug"} element={<VideoSingle/>}/>
                    <Route exact path={"/contact-us"} element={<ContactUs/>}/>
                    <Route exact path={"/portfolio"} element={<Portfolio/>}/>
                    <Route exact path={"/portfolio/:id"} element={<PortfolioSingle/>}/>
                    <Route exact path={"/blog"} element={<Blog/>}/>

                    <Route exact path={"/:category"} element={<News/>}/>
                    <Route exact path={"/:category/:slug"} element={<NewsSingle/>}/>
                    <Route exact path={"*"} element={<PageNotFound/>}/>
                </Routes>
                {/*<Footer/>*/}
            </>
        );
    }

    const render = () => {
        if (window.location.hostname === '127.0.0.1' || window.location.hostname == 'codestudionews.com' || window.location.hostname == 'www.codestudionews.com') {
            return renderNews();
        }

        return renderMain();
    }

    return render();
}

export default App;
