import React from 'react'
import mission from '../../assets/images/mission.png'

const BlogFeaturedPost = () => {
    return (

        <>
            <div className={'text-center container'}>
                <div className={'row flex-column'}>
                    <div className={'col pt-5 mt-3'}>
                        <h1 className={"bebas font-600 font-size-50 text-decoration-underline pt-5"}>A UX Case Study on Creating a Studious Environment for <br /> Students</h1>
                    </div>
                </div>

                <div>
                    <span className={'text-pink sora font-size-24'}>Saad Niazi</span>
                    <span className={'ms-2 sora font-size-24'}>Posted on 29th September 2022</span>
                </div>

                <div className={'container'}>
                    <div className={'row'}>
                        <img
                            src={mission}
                            style={{
                                height: "446px",
                                objectFit: "cover"
                            }}
                            className={'col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-4'}
                        />
                    </div>
                </div>

                <div className={'container text-center p-3'}>
                    <span>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside.</span>
                </div>

                <div className={'offset-4 col-4 text-center mt-4 pb-5'}>
                    <button className={'btn btn-violet rounded-0 bebas font-size-50 font-800'}>GET QUOTE</button>
                </div>
            </div>
        </>
    )
}

export default BlogFeaturedPost