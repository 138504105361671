import React, {useState} from 'react';
import Marquee from 'react-fast-marquee';

const MarqueeEffect = (props) => {
  const {children} = props
    return(
        <Marquee pauseOnHover={true} gradient={false}>
          {children}
        </Marquee>
    )
};

export default MarqueeEffect;
