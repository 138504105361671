const PinkCorner = (props) => {
  const { thirdPackage, isMobile } = props;
  const renderMain = () => {
    return (
      <div
        style={{
          alignSelf: "center",
          borderTopRightRadius: thirdPackage && !isMobile ? null : "30px",
          borderBottomRightRadius: thirdPackage && !isMobile ? null : "30px",
          borderTopLeftRadius: thirdPackage && !isMobile ? "30px" : null,
          borderBottomLeftRadius: thirdPackage && !isMobile ? "30px" : null,
          backgroundColor: thirdPackage ? "#7A28CB" : "#ED2F59",
          width: "30px",
          height: "20px",
          position: "absolute",
          left: thirdPackage ? null : "-13px",
          right: thirdPackage && !isMobile ? "-13px" : null,
        }}
      ></div>
    );
  };

  return renderMain();
};

export default PinkCorner;
