import DesignLogo from '../../assets/images/Design.png'
import DevelopmentLogo from '../../assets/images/Dev.png'
import IdeaLogo from '../../assets/images/tips_and_updates_FILL0_wght400_GRAD0_opsz48.png'
import MarketingLogo from '../../assets/images/Marketing.png'
import {useState} from 'react';
import CenterPopup from '../Popovers/CenterPopup';
import {GetQuoteButton} from "../GetQuoteButton";

export const ServicesComponent = () => {

    const [modalOneShow, setModalOneShow] = useState(false);
    const [modalTwoShow, setModalTwoShow] = useState(false);
    const [modalThreeShow, setModalThreeShow] = useState(false);
    const [modalFourShow, setModalFourShow] = useState(false);


    return (
        <div className={`container mt-5 pt-5 pb-5 p-lr-20`}>
            <div className={`row`}>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex p-2 align-items-stretch`}>
                    <div className={`bg-white shadow-sm p-4`}>
                        <div className={`col-2 mb-3`}>
                            <img src={require('../../assets/images/Design.png')} alt={'Code Studio Astonishing Design'} className={`img-fluid`}/>
                        </div>
                        <div className={`bebas font-size-30 font-500 text-xiketic text-decoration-underline mt-2`}
                             style={{
                                 lineHeight: '35px'
                             }}>
                            Astonishing <br/> Design
                        </div>
                        <div className={`sora font-size-16 text-xiketic mt-3 pe-3 text-four-line-ellipsis`}>
                            At Code Studio, we follow the 5 stages of the design thinking process to create astonishing designs you can be proud of.
                        </div>
                        <div className={`mt-4`}>
                            <button className={`btn btn-violet rounded-0 bebas font-500 font-size-20`} onClick={() => setModalOneShow(true)}>
                                Read More
                            </button>
                            <CenterPopup
                                show={modalOneShow}
                                onHide={() => setModalOneShow(false)}
                                heading="Astonishing Design"
                                body="We follow the 5 stages of design: Understanding the users needs, Stating the users needs and problems, Coming up with innovative ideas, Presenting solutions to solve those problems and finally testing out the solutions."
                                body1="By following the exact strategies above, our creative design team produces designs that are unique and equally befitting to a global and local clientèle from different niches."
                                imgsrc={DesignLogo}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex p-2 align-items-stretch`}>
                    <div className={`bg-white shadow-sm p-4`}>
                        <div className={`col-2 mb-3`}>
                            <img src={require('../../assets/images/Dev.png')} alt={'Code Studio Professional Development'} className={`img-fluid`}/>
                        </div>
                        <div className={`bebas font-size-30 font-500 text-xiketic text-decoration-underline mt-2`}
                             style={{
                                 lineHeight: '35px'
                             }}>
                            Professional <br/> Development
                        </div>
                        <div className={`sora font-size-16 text-xiketic mt-3 pe-3 text-four-line-ellipsis`}>
                            With multiple successfully completed enterprise software projects, Code Studio specializes in end to end web design and development services.
                        </div>
                        <div className={`mt-4`}>
                            <button className={`btn btn-violet rounded-0 bebas font-500 font-size-20`} onClick={() => setModalTwoShow(true)}>
                                Read More
                            </button>
                            <CenterPopup
                                show={modalTwoShow}
                                onHide={() => setModalTwoShow(false)}
                                heading="Professional Development"
                                body="Get a modern website for your business and stand out from the competition with a unique and user-friendly and accessible website design"
                                body1="At Code Studio, we focus on making websites with responsive layouts, which are search engine friendly with a great user experience."
                                imgsrc={DevelopmentLogo}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex p-2 align-items-stretch`}>
                    <div className={`bg-white shadow-sm p-4`}>
                        <div className={`col-2 mb-3`}>
                            <img src={require('../../assets/images/tips_and_updates_FILL0_wght400_GRAD0_opsz48.png')} alt={'Code Studio Idiosyncratic Ideas'} className={`img-fluid`}/>
                        </div>
                        <div className={`bebas font-size-30 font-500 text-xiketic text-decoration-underline mt-2`}
                             style={{
                                 lineHeight: '35px'
                             }}>
                            Idiosyncratic <br/> Ideas
                        </div>
                        <div className={`sora font-size-16 text-xiketic mt-3 pe-3 text-four-line-ellipsis`}>
                            Brands are living business assets. At Code Studio we build a strategic and creative foundation of your business with our idiosyncratic ideas.
                        </div>
                        <div className={`mt-4`}>
                            <button className={`btn btn-violet rounded-0 bebas font-500 font-size-20`} onClick={() => setModalThreeShow(true)}>
                                Read More
                            </button>
                            <CenterPopup
                                show={modalThreeShow}
                                onHide={() => setModalThreeShow(false)}
                                heading="Idiosyncratic Ideas"
                                body="Code Studio, with its creative design team, provides matchless web designing services to a global clientèle from different niches with our idiosyncratic ideas."
                                body1="Our innovative and ingenious designers adhere to the requirements of the clients and produce designs that are unique and equally befitting to the needs of our clients. Your satisfaction is our first priority."
                                imgsrc={IdeaLogo}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex p-2 align-items-stretch`}>
                    <div className={`bg-white shadow-sm p-4`}>
                        <div className={`col-2 mb-3`}>
                            <img src={require('../../assets/images/Marketing.png')} alt={'Code Studio Digital Marketing'} className={`img-fluid`}/>
                        </div>
                        <div className={`bebas font-size-30 font-500 text-xiketic text-decoration-underline mt-2`}
                             style={{
                                 lineHeight: '35px'
                             }}>
                            Digital <br/> Marketing
                        </div>
                        <div className={`sora font-size-16 text-xiketic mt-3 pe-3 text-four-line-ellipsis`}>
                            Code Studio’s Digital marketing department specializes in creating strategies that can grow your business.
                        </div>
                        <div className={`mt-4`}>
                            <button className={`btn btn-violet rounded-0 bebas font-500 font-size-20`} onClick={() => setModalFourShow(true)}>
                                Read More
                            </button>
                            <CenterPopup
                                show={modalFourShow}
                                onHide={() => setModalFourShow(false)}
                                heading="Digital Marketing"
                                body={'Get superior Digital Marketing solutions for your business that generate sales and supercharge your growth with Code Studio.'}
                                body1={'With our strategies, acquire a higher ROI, get more leads and get an improved customer engagement.'}
                                imgsrc={MarketingLogo}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`pt-5 mt-5 d-flex flex-row justify-content-center`}>
                <hr
                    style={{
                        width: '40%',
                        backgroundColor: '#ED2F59',
                        border: 'none',
                        height: '5px',
                        opacity: '1',
                        marginTop: '2em',
                    }}
                    className={`d-none d-xl-block d-lg-block d-md-block`}
                />
                <div className={'text-center mt-2 ms-5 me-5'}>
                    <GetQuoteButton/>
                </div>
                <hr
                    style={{
                        width: '40%',
                        backgroundColor: '#ED2F59',
                        border: 'none',
                        height: '5px',
                        opacity: '1',
                        marginTop: '2em',
                    }}
                    className={`d-none d-xl-block d-lg-block d-md-block`}
                />
            </div>
        </div>
    )
}
