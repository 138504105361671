import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from "../../Constants/ScreenSizes";
import ItemLoader from "../Loaders/ItemLoader";
import {useParams} from "react-router-dom";
import {NewsApi} from "../../Axios/EndPoints";
import {get} from "../../Axios/get";
import NewsCard from "./NewsCard";
import GoogleAd from "../GoogleAd";

const RightNews = () => {
    const {category} = useParams();

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isItemLoading, setItemLoading] = useState(true);
    const [latestNews, setLatestNews] = useState([]);

    const fetchLatestNews = () => {
        let categorySlug = '';
        let limit = 2;
        if (category) {
            categorySlug = category;
        }
        let parameters = `?page=${1}&limit=${limit}&latest=true`;
        get(NewsApi + categorySlug + parameters)
            .then((response) => {
                setLatestNews(response.data.response);
                setLoading(false);
                setItemLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
                setItemLoading(false);
            });
    }

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        setLatestNews([]);
        setLoading(true);
        fetchLatestNews();
    }, [category]);

    const renderMoreNews = () => {

        if (latestNews.length <= 0) {
            return <></>;
        }
        return (<div className={'container bg-white mb-5'}>
            {!category && <p className={`${isMobile ? "font-size-24" : "font-size-30"} ${isMobile && "ms-2"} text-dark-blue mb-4 bebas font-500 text-decoration-underline`}>More News</p>}
            <div className={`row ${isMobile && 'ms-auto'}`}>
                {latestNews.map((value, index) => {
                    return (
                        <>
                            {index === 1 ? <div className={'col-12 ad-height'} style={{height: '250px'}}><GoogleAd id={'9462835128'}/></div> : <></>}
                            <div className={`col-12`} key={`more_news_${value.id}`}>
                                <NewsCard
                                    news={value}
                                    isMobile={isMobile}
                                    isNewsBelow={true}
                                    color={'text-dark-blue'}
                                    newsCard={"newsCardEight"}
                                />
                            </div>
                        </>
                    );
                })}
                <ItemLoader loading={isItemLoading}/>
            </div>
        </div>)
    }

    const render = () => {
        return (<>
            <div className={`${isMobile ? "d-none" : "col-4"}`}>
                {renderMoreNews()}
            </div>
        </>);
    }

    return isLoading ? (<ItemLoader loading={isLoading}/>) : render();
};

export default RightNews;
