import OldAndNewPrice from "./OldAndNewPrice";
import NumberOfPackagePoints from "./NumberOfPackagePoints";
import Bronze from "../../assets/images/Bronze.png";
import PackageName from "./PackageName";
import Silver from "../../assets/images/Silver.png";
import PinkCorner from "./PinkCorner";
import PackagePrice from "./PackagePrice";
import socialMediaMesh from "../../assets/images/socialMediaPackagesMesh.png";

const SecondPackage = (props) => {
  const { isMobile, darkBg } = props;

  const renderMain = () => {
    return (
      <div
        className={
          "d-flex flex-column position-relative h-100 justify-content-between"
        }
      >
        <div
          className={`d-flex flex-column mb-5 ${
            isMobile ? "mt-3" : null
          } position-relative`}
        >
          {!isMobile ? (
            <PackageName
              isMobile={isMobile}
              packageName={"SILVER"}
              imgName={Silver}
            />
          ) : null}

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"5 Photo Posts Per Week"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"5 Stories Per Week"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"12 Reels Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"3 Videos Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"7 Restaurant Listing Websites"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"5 Facebook Ad Campaigns Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Facebook Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Instagram Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Facebook Ads Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Facebook Shop & Pixel Setup"}
            />
          </div>
          {/**/}

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Instagram Shop Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Google Merchant Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Twitter Shop Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Google Business Profile Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Social Media Audit - Medium"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Account Manager - Shared"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Weekly Report"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <NumberOfPackagePoints
              darkBg={true}
              secondPackage={true}
              isMobile={isMobile}
              point={"- Support Within 6 hours"}
            />
          </div>

          <hr
            className={"customHR ms-4"}
            style={{ color: darkBg ? "white" : "purple", width: "90%" }}
          />
        </div>

        <PackagePrice
          darkBg={true}
          isMobile={isMobile}
          oldPrice={"£500"}
          newPrice={"349"}
          packageName={"SILVER"}
        />
      </div>
    );
  };

  return renderMain();
};

export default SecondPackage;
