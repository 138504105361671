import React from 'react'
import {Card} from 'react-bootstrap'
import ServicesImage from '../../assets/images/resized/Services-We-Offer.png'
import servicesDesignLogo from '../../assets/images/services/Design.svg'
import softwareDevelopmentLogo from '../../assets/images/services/web-design.svg'
import smmLogo from '../../assets/images/services/bullhorn 1.svg'
import gdbLogo from '../../assets/images/services/Rectangle 34.svg'
import dmLogo from '../../assets/images/services/domain 1.svg'
import '../../assets/css/background-colors.css';
import {Link} from "react-router-dom";
import {GetQuoteButton} from "../GetQuoteButton";

export const ServicesComponent = () => {
    return (
        <div className={'container bg-light-pink'}>
            <div className={'row'}>
                <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                    <Card className='rounded-0 w-100'>
                        <Card.Img variant="top" src={ServicesImage}
                                  style={{
                                      objectFit: "cover",
                                      margin: "auto"
                                  }}
                                  className={'p-3 img-fluid'}
                        />
                        <Card.Body>
                            <Card.Title className={'bebas font-size-30 font-600 text-decoration-underline'}>
                                SERVICES WE OFFER
                            </Card.Title>
                            <Card.Text>
                                Our services include the following but are no limited to:
                            </Card.Text>
                            <div className={`mt-4 text-white`}>.</div>
                            <div className='mt-5 pt-4'>
                                <GetQuoteButton/>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6'}>
                    <div className={'row g-4'}>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}
                        >
                            <Card className='rounded-0 w-100'>
                                <Card.Img variant="top" src={servicesDesignLogo}
                                          style={{
                                              objectFit: "cover",
                                              margin: "auto",
                                              width: "40%",
                                              height: "auto"
                                          }}
                                          className={'p-3 img-fluid'}
                                />
                                <Card.Body>
                                    <Card.Title className={'sora font-size-18 font-400 text-center'}>
                                        Web Design and Development
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 w-100'>
                                <Card.Img variant="top" src={softwareDevelopmentLogo}
                                          style={{
                                              objectFit: "cover",
                                              margin: "auto",
                                              width: "40%",
                                              height: "auto"
                                          }}
                                          className={'p-3 img-fluid'}
                                />
                                <Card.Body>
                                    <Card.Title className={'sora font-size-18 font-400 text-center'}>
                                        Software
                                        <br/>
                                        Development
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 mt-3 w-100'>
                                <Card.Img variant="top" src={smmLogo}
                                          style={{
                                              objectFit: "cover",
                                              margin: "auto",
                                              width: "40%",
                                              height: "auto"
                                          }}
                                          className={'p-3 img-fluid'}
                                />
                                <Card.Body>
                                    <Card.Title className={'sora font-size-18 font-400 text-center'}>
                                        Social Media Marketing - Campaigning
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 mt-3 w-100'>
                                <Card.Img variant="top" src={gdbLogo}
                                          style={{
                                              objectFit: "cover",
                                              margin: "auto",
                                              width: "40%",
                                              height: "auto"
                                          }}
                                          className={'p-3 img-fluid'}
                                />
                                <Card.Body>
                                    <Card.Title className={'sora font-size-18 font-400 text-center'}>
                                        Graphic Design and Branding
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 mt-3 w-100'>
                                <Card.Img variant="top" src={dmLogo}
                                          style={{
                                              objectFit: "cover",
                                              margin: "auto",
                                              width: "40%",
                                              height: "auto"
                                          }}
                                          className={'p-3 img-fluid'}
                                />
                                <Card.Body>
                                    <Card.Title className={'sora font-size-18 font-400 text-center'}>
                                        Domain
                                        <br/>
                                        Management
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-3 align-items-stretch d-flex mb-5 pb-5'}>
                            <Link to={'/services/detail'} className={'flip-card w-100'}>
                                <Card className='rounded-0 flip-card-inner w-100'>
                                    <Card.Body className={'flip-card-front'}>
                                        <Card.Title className={'bebas font-size-40 p-2 font-500 text-center'}>
                                            Discover
                                            <br/>
                                            More
                                            <br/>
                                            Services
                                        </Card.Title>
                                    </Card.Body>
                                    <Card.Body className={'flip-card-back'}>
                                        <Card.Title className={'bebas font-size-40 p-2 font-500 text-center'}>
                                            Discover
                                            <br/>
                                            More
                                            <br/>
                                            Services
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}