import {AboutComponent} from "./AboutComponent";

export const BannerComponent = () => {
    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <div className={'banner-text'}>
                    <AboutComponent/>
                </div>
            </div>
        </div>
    )
}