import {useState} from "react";
import {FaPlus, FaTimes} from "react-icons/fa";
import PropTypes from "prop-types";

export const FAQSingleComponent = (props) => {

    const [open, setOpen] = useState(false);

    const faq = props.faq;

    const clickHandler = () => {
        setOpen(!open);
    }

    const render = () => {
        return (
            <div className={`row mt-3`} onClick={clickHandler} key={`faq_${faq.index}`}>
                <div className={`col-1 sora text-pink font-600`}>
                    {faq.id}
                </div>
                <div className={`col-10`}>
                    <div className={`sora font-400 font-size-20`}>
                        {faq.question}
                    </div>
                    <div className={`sora font-300 font-size-16 mt-2 ${open ? 'd-block' : 'd-none'}`}
                    >
                        {faq.answer}
                    </div>
                </div>
                <div className={`col-1 sora text-pink font-600`}>
                    {
                        open ?
                            <FaTimes
                                style={{cursor: 'pointer'}}
                                onClick={clickHandler}
                            />
                            :
                            <FaPlus
                                style={{cursor: 'pointer'}}
                                onClick={clickHandler}
                            />
                    }
                </div>
            </div>
        )
    }

    return render();
}

FAQSingleComponent.propTypes = {
    faq: PropTypes.object.isRequired,
};