import Modal from 'react-bootstrap/Modal';
import './CenterPopup.css'
import {useEffect, useState} from "react";
import validator from "validator/es";
import axios from "axios";
import {ContactUsAPI} from "../../Axios/EndPoints";
import {FormControl, FormLabel} from "react-bootstrap";


export default function GetQuotePopup(props) {

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [social, setSocial] = useState('');
    const [company, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [message, setMessage] = useState(props.message || '');

    const [isPostSuccess, setIsPostSuccess] = useState(null);

    const handleSubmitClick = () => {
        let data = null;

        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }
        if (message === '') {
            canBeSubmitted = false;
            setErrorMessage(true);
        }

        if (canBeSubmitted) {
            data = {
                name: name,
                email: email,
                phone: phone,
                social_media_profile: social,
                business_name: company,
                website: website,
                message: message
            };
            postData(data);
        }
    }

    const postData = (data) => {
        axios.post(ContactUsAPI, data).then((response) => {
            if (response.data.success) {
                setIsPostSuccess('success');
            } else {
                setIsPostSuccess('danger');
            }
        })
    }

    useEffect(() => {
    }, [errorName, errorEmail, errorPhone, errorMessage]);
    return (
        <div>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={'lg'}
                className={'rounded-0'}
            >
                <Modal.Header closeButton className={'border-0 rounded-0'}>
                </Modal.Header>
                <Modal.Body>
                    <div className={`row ps-5 pe-5 pb-5`}>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Name</FormLabel>
                            <FormControl
                                isInvalid={errorName}
                                type="text"
                                id="name"
                                aria-describedby="name"
                                placeholder="Your Name"
                                className={`contact-us-text-input mb-3`}
                                value={name}
                                onChange={(e) => {
                                    setErrorName(false);
                                    setName(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input name</FormControl.Feedback>
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                isInvalid={errorEmail}
                                type="email"
                                id="email"
                                aria-describedby="email"
                                placeholder="Your Email"
                                className={`contact-us-text-input mb-3`}
                                value={email}
                                onChange={(e) => {
                                    setErrorEmail(false);
                                    setEmail(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid email</FormControl.Feedback>
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Phone Number</FormLabel>
                            <FormControl
                                isInvalid={errorPhone}
                                type="text"
                                id="phone"
                                aria-describedby="phone"
                                placeholder="Your Phone Number"
                                className={`contact-us-text-input mb-3`}
                                value={phone}
                                onChange={(e) => {
                                    setErrorPhone(false);
                                    setPhone(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid phone number</FormControl.Feedback>
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Social Media Profile</FormLabel>
                            <FormControl
                                type="url"
                                id="social"
                                aria-describedby="social"
                                placeholder="Social Media Profile ( if any )"
                                className={`contact-us-text-input mb-3`}
                                value={social}
                                onChange={(e) => {
                                    setSocial(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Company / Business Name</FormLabel>
                            <FormControl
                                type="text"
                                id="company"
                                aria-describedby="company"
                                placeholder="Company Name"
                                className={`contact-us-text-input mb-3`}
                                value={company}
                                onChange={(e) => {
                                    setCompany(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start`}>
                            <FormLabel>Website</FormLabel>
                            <FormControl
                                type="url"
                                id="website"
                                aria-describedby="website"
                                placeholder="Website Address"
                                className={`contact-us-text-input mb-3`}
                                value={website}
                                onChange={(e) => {
                                    setWebsite(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 text-start`}>
                            <FormLabel>Message</FormLabel>
                            <FormControl
                                isInvalid={errorMessage}
                                as='textarea'
                                rows={6}
                                id="message"
                                aria-describedby="message"
                                placeholder="Your Query"
                                className={`contact-us-text-input mb-3`}
                                value={message}
                                onChange={(e) => {
                                    setErrorMessage(false);
                                    setMessage(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input message</FormControl.Feedback>
                        </div>
                        <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 text-start`}>
                            <button className={`btn btn-violet rounded-0 bebas font-600 font-size-20`} onClick={handleSubmitClick}>
                                Get Quote
                            </button>
                            {
                                isPostSuccess !== null && (
                                    <div className={`alert alert-${isPostSuccess} alert-dismissible`}>
                                        {
                                            isPostSuccess === 'success' ?
                                                'Successfully submitted' :
                                                'Some error occurred. Please try again later'
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}


