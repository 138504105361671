import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PortfolioLoader from "../Loaders/PortfolioLoader";


export const PortfolioSingleComponent = (props) => {

    const item = props.item;
    const loading = props.loading

    useEffect(() => {
    }, [props.item]);



    const render = () => {
        return (
            <>
                <div
                    className={`col-xl-6 col-lg-6 col-md-6 col-sm-12  d-flex flex-column align-items-stretch mt-5`}
                >
                    <div
                        className={`col-sm-12 d-flex align-items-stretch flex-column m-2 p-2`}
                    >
                        <img src={`${item.thumbnail}?text=${item.name}`} alt={item.title} className={'img-fluid'} style={{ height: "300px", objectFit: "cover" }} />
                        <div className={`sora mt-4 font-700 font-size-20`}>
                            {item.name}
                        </div>
                        <div className={`sora mt-4 font-400 font-size-14`}>
                            {item.short_description}
                        </div>
                        <div className={`mt-3`}>
                            <Link to={`/portfolio/${item.uid}`} className={`btn btn-violet rounded-0 bebas font-400 font-size-20 `}>
                                View Portfolio
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return render();
}

PortfolioSingleComponent.propTypes = {
    item: PropTypes.object.isRequired
}
