import Navigation from "./Navigation";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import moment from "moment";
import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export const Footer = () => {
    const location = useLocation();

    const [showFooter, setShowFooter] = useState(true);

    useEffect(() => {
    }, [showFooter]);
    useEffect(() => {
        setShowFooter(true);
        let locArr = location.pathname.split('/');
        if (locArr[1] === 'news' && typeof locArr[2] !== 'undefined') {
            setShowFooter(false);
        }
    }, [location.pathname]);

    const render = () => {
        return (<>
            <div className={`bg-dark pt-5 pb-5 opacity-99 p-lr-20`}>
                <div className={`container pt-5 pb-5`}>
                    <div className={`row`}>
                        <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12`}>
                            <div className={`bebas font-500 font-size-30 text-white text-decoration-underline`}>
                                Get in Touch with us for your Service
                            </div>
                            <div className={'container'}>
                                <div className={`d-flex flex-row justify-content-between mt-5`}>
                                    <div className={`justify-content-center text-center btn-white-outline d-flex flex-column`}
                                         style={{
                                             height: '50px',
                                             width: '50px',
                                         }}
                                         onClick={() => {
                                             window.open('https://fb.com/codestudiopak', '_blank');
                                         }}>
                                        <FaFacebookF className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                                    </div>
                                    <div className={`justify-content-center text-center btn-white-outline d-flex flex-column`}
                                         style={{
                                             height: '50px',
                                             width: '50px',
                                         }}
                                         onClick={() => {
                                             window.open('https://instagram.com/codestudiopak', '_blank');
                                         }}>
                                        <FaInstagram className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                                    </div>
                                    <div className={`justify-content-center text-center btn-white-outline d-flex flex-column`}
                                         style={{
                                             height: '50px',
                                             width: '50px',
                                         }}
                                         onClick={() => {
                                             window.open('https://twitter.com/codestudiopak', '_blank');
                                         }}>
                                        <FaTwitter className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                                    </div>
                                    <div className={`justify-content-center text-center btn-white-outline d-flex flex-column`}
                                         style={{
                                             height: '50px',
                                             width: '50px',
                                         }}
                                         onClick={() => {
                                             window.open('https://linkedin.com/company/codestudiopak', '_blank');
                                         }}>
                                        <FaLinkedinIn className={`flex-column text-center justify-content-center w-100 font-size-18`}/>
                                    </div>
                                    F
                                </div>
                            </div>
                            <div className={'container mt-5 pt-5 text-white sora'}>
                                &copy; Copyright Code Studio {moment().year()}
                            </div>
                        </div>
                        <div className={`offset-xl-3 offset-lg-3 offset-md-3 offset-sm-0 col-xl-4 col-lg-4 col-md-4 col-sm-12`}>
                            <div className={`container`} key={'number'}>
                                <div className={'sora text-secondary'}>
                                    Helpline Numbers
                                </div>
                                <div className={'sora text-white'}>
                                    <a href={'tel:+44 744 142 8091'} className={'text-white text-decoration-none'}>+44 744 142 8091</a>
                                </div>
                                <div className={'sora text-white'}>
                                    <a href={'tel:+92 348 021 6565'} className={'text-white text-decoration-none'}>+92 348 021 6565</a>
                                </div>
                            </div>
                            <div className={`container mt-5`} key={'email'}>
                                <div className={'sora text-secondary'}>
                                    Emails
                                </div>
                                <div className={'sora text-white'}>
                                    <a href={'mailto:info@codestudio.solutions'} className={'text-white text-decoration-none'}>info@codestudio.solutions</a>
                                </div>
                                <div className={'sora text-white'}>
                                    <a href={'mailto:info@codestudio.com.pk'} className={'text-white text-decoration-none'}>info@codestudio.com.pk</a>
                                </div>
                            </div>
                            <div className={`container mt-5`} key={'address'}>
                                <div className={'sora text-secondary'}>
                                    Addresses
                                </div>
                                <div className={'sora text-white'}>
                                    124-128 City Road, London, EC1V 2NX
                                </div>
                                <div className={'sora text-white'}>
                                    Plot 1341, Street 2, G-14/4, Islamabad, Pakistan
                                </div>
                            </div>
                            <div className={`container mt-5`} key={'workingHours'}>
                                <div className={'sora text-secondary'}>
                                    Working Hours
                                </div>
                                <div className={'sora text-white'}>
                                    Monday–Friday: 10:00AM–8:00PM
                                    <br/>
                                    Saturday & Sunday: Closed
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navigation bg={'dark'} className={'d-none d-md-block'}/>
        </>)
    }

    return showFooter && render();
}
