import {Tab, Tabs} from "react-bootstrap";
import {SingleCategoryComponent} from "./SingleCategoryComponent";
import {useEffect, useState} from "react";
import {WorkCategoriesAPI} from "../../Axios/EndPoints";
import axios from "axios";

export const CategoriesComponent = () => {

    const [categories, setCategories] = useState([]);
    const [activeTab, setActiveTab] = useState(null);

    const fetchCategories = () => {
        axios
            .get(
                `${WorkCategoriesAPI}`
            )
            .then((res) => {
                setCategories(res.data.response)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchCategories();
        setActiveTab(0);
    }, []);

    const handleTabChange = (key) => {
        setActiveTab(key);
    }

    const render = () => {
        return (
            <div className={`container mt-5 pt-5 mb-5 pb-5`}>
                <Tabs
                    defaultActiveKey={0}
                    fill={true}
                    variant={"tabs"}
                    onSelect={handleTabChange}
                >
                    <Tab title={"All"} eventKey={0} key={`cat_tab_0`}>
                        <SingleCategoryComponent category_id={0} key={`cat_0`} active={activeTab}/>
                    </Tab>
                    {
                        categories?.map((category, index) => (
                            <Tab title={category.category} eventKey={category.id} key={`cat_tab_${index}`}>
                                <SingleCategoryComponent category_id={category.id} key={`cat_${index}`} active={activeTab}/>
                            </Tab>
                        ))
                    }
                </Tabs>
            </div>
        );
    }

    return render();
}