import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {PortfolioSingleComponent} from "./PortfolioSingleComponent";
import {PortfolioItemsAPI} from "../../Axios/EndPoints";
import PortfolioLoader from "../Loaders/PortfolioLoader";
import {get} from "../../Axios/get";
import ItemLoader from "../Loaders/ItemLoader";

export const SingleCategoryComponent = (props) => {

    const [portfolioItems, setPortfolioItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiPage, setApiPage] = useState(1);

    const viewMoreClicked = () => {
        setLoading(true);
        let tempApiPage = apiPage;
        setApiPage(++tempApiPage);
    }

    const fetchPortfolioItems = () => {
        let URL = PortfolioItemsAPI + props.active;

        let parameters = `?page=${apiPage}`;

        get(URL + parameters)
            .then((res) => {
                setPortfolioItems([...portfolioItems, ...(res.data.response)]);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        setLoading(true);
        setPortfolioItems([]);
        props.active != null && props.category_id.toString() === props.active.toString() && fetchPortfolioItems();
    }, [props.active]);

    useEffect(() => {
        apiPage !== 1 && fetchPortfolioItems();
    }, [apiPage]);

    const render = () => {
        return (
            <>
                {
                    loading && (portfolioItems.length <= 0 ? <PortfolioLoader/> : <ItemLoader loading={loading} />)
                }
                {
                    <div className={`container`}>
                        <div className={`row`}>
                            {
                                portfolioItems?.map((item, index) => (
                                    <PortfolioSingleComponent item={item} key={`pf_${index}`} loading={loading}/>
                                ))
                            }
                        </div>
                        <div className={'text-center mt-5 pt-5'}>
                            <button className={`btn btn-pink rounded-0 bebas font-400 font-size-24 ps-5 pe-5`} onClick={viewMoreClicked}>
                                View More
                            </button>
                        </div>
                    </div>
                }

            </>

        );
    }

    return render();
}

SingleCategoryComponent.propTypes = {
    active: PropTypes.any,
    category_id: PropTypes.any,
}
