import {useState} from "react";
import GetQuotePopup from "../Popovers/GetQuotePopup";

const PackagePrice = (props) => {
    const {isMobile, oldPrice, newPrice, darkBg, packageName} = props;

    const [showModal, setShowModal] = useState(false);

    const render = () => {
        return (
            <div
                onClick={() => setShowModal(true)}
                className={
                    "d-flex align-items-center flex-column justify-content-center w-100 mb-5 cursor-pointer"
                }
            >
                <div className={"d-flex"}>
                    <span
                        className={`${
                            darkBg ? "text-white" : "text-dark"
                        } font-800 font-size-18`}
                    >
                        Only in
                    </span>
                    <span
                        className={`${
                            darkBg ? "text-white" : "text-dark"
                        } font-800 cross font-size-18 ms-1 me-1`}
                    >
                        {oldPrice}
                    </span>
                    <span
                        className={`${
                            darkBg ? "text-white" : "text-dark"
                        } font-800 font-size-18`}
                    >
                        Per Month
                    </span>
                </div>
                <div
                    className={
                        "bg-violet w-50 d-flex align-items-center justify-content-center"
                    }
                    style={{borderRadius: "40px"}}
                >
                    <span className={"text-white font-size-50 font-800"}>{newPrice}</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {render()}
            <GetQuotePopup
                show={showModal}
                setShowModal={setShowModal}
                onHide={() => setShowModal(false)}
                message={`Selected ${packageName} Package`}
            />
        </>
    );
};

export default PackagePrice;
