import {Link} from "react-router-dom";

export const AboutComponent = () => {
    return (
        <div className={`container mt-5 mb-5 pt-5 pb-5 p-lr-20`}>
            <div className={`row`}>
                <div className={`col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center`}>
                    <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                        OUR MISSION IS TO HARNESS OUR CREATIVITY INTO A VIABLE SOLUTION FOR YOUR BUSINESS.
                    </div>
                    <div className={`sora`}>
                        <br/>
                        Back in 2017, Code Studio started out as a small one room company working on website and software development. With an ambition to grow global, we’re now a team of
                        skilled professionals catering to both National and International clients. In addition to a growing team, we have expanded our service portfolio to include but not limited to:
                        <br/>
                        <br/>
                        <ul className={``}>
                            <li>Web design and web development</li>
                            <li>Software development</li>
                            <li>Standalone and cloud service deployments</li>
                            <li>Database design and development</li>
                            <li>Mobile application development</li>
                            <li>Graphic design and Branding</li>
                            <li>Digital marketing</li>
                            <li>Domain and Hosting management</li>
                            <li>SEO</li>
                        </ul>
                        Our mission is simple: make technology an asset for your business not a problem.
                    </div>
                    <div className={`mt-4`}>
                        <Link className={`btn btn-violet rounded-0 bebas font-500 font-size-20`} to={`/services/detail`}>Read More</Link>
                    </div>
                </div>
                <div className={`offset-xl-2 offset-lg-2 offset-md-2 offset-sm-9 col-xl-3 col-lg-3 col-md-3 col-sm-12 text-end`}>
                    <img src={require('../../assets/images/resized/Our-Mission.png')} alt={'About Code Studio'} className={`img-fluid`}/>
                </div>
            </div>
        </div>
    )
}
