const baseUrl = "https://codestudio.solutions/api/v1";
// const baseUrl = "http://localhost:8002/api/v1";

export const ClientsAPI = `${baseUrl}/clients`;
export const FAQ_API = `${baseUrl}/faqs`;
export const WorkCategoriesAPI = `${baseUrl}/our-work-categories`;
export const PortfolioItemsAPI = `${baseUrl}/our-work/`;
export const PortfolioSingleApi = `${baseUrl}/portfolio/`;
export const ContactUsAPI = `${baseUrl}/contact-us/`;
export const NewsCategoriesApi = `${baseUrl}/categories/`;
export const NewsApi = `${baseUrl}/news/`;
export const SingleNewsApi = `${baseUrl}/single/news/`;
export const VideosCategoriesApi = `${baseUrl}/categories/`;
export const VideosApi = `${baseUrl}/videos/`;
export const SingleVideosApi = `${baseUrl}/single/videos/`;
export const STORE_FCM_TOKEN = `${baseUrl}/fcm/`;
