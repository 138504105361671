import React from 'react'

const WriteBlogs = () => {
    return (
        <div className={'container-fluid bg-light-pink-gradient p-5'}>
            <div className={'row flex-column text-center'}>
                <span className='bebas font-600 font-size-50  text-decoration-underline pt-3'>
                    WANT TO WRITE A BLOG ON OUR SITE
                </span>
                <span className={'font-size-16 mt-3'}>
                    Nullam vitae purus at tortor mattis dapibus. Morbi purus est, ultricies <br /> nec dolor sit amet, scelerisque cursus purus.
                </span>
                <div className={'text-center mt-4 pb-4'}>
                    <button className={'btn btn-violet rounded-0 bebas font-size-50 px-3 font-600'}>CLICK HERE</button>
                </div>
            </div>
        </div>
    )
}

export default WriteBlogs