import {Card, Image} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import HTMLEllipsis from "react-lines-ellipsis/lib/html.modern.mjs";
import moment from "moment";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {SCREEN_SIZE} from "../../Constants/ScreenSizes";

const NewsCard = (props) => {
    const {
        news, newsCard, isNewsOverImage, isLargeImage, isNewsSide, isNewsBelow, imageURL, category,
        author, newsTitle, bgColor, fontColor, extraClass
    } = props;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    const setDefaultImage = (ev) => {
        ev.target.src = require("../../assets/images/defaultNewsImage.jpg");
    }

    const renderNewsOverImage = () => {
        let className = `${newsCard}Styles`;

        return (
            <Card className={`col border-0 bg-transparent mb-1 ${isMobile ? 'w-100' : 'w-fit-content'}`}>
                <Card.Img
                    src={news.source_image}
                    alt={news.title}
                    className={`${className} card-image-radius-zero`}
                    style={{height: props.height || 'auto', objectFit: 'cover'}}
                    onError={setDefaultImage}
                />

                <Card.ImgOverlay
                    className={`d-flex align-items-start ${isNewsOverImage && 'news-overlay'} rounded-custom cursor-pointer `}>
                    <Card.Text className={`sora text-white font-size-12 zIndexClass`}
                               style={{backgroundColor: news.category?.background_color?.replaceAll('##', '#'), color: news.category?.font_color?.replaceAll('##', '#')}}>
                        <Link to={`/news/${news.category?.slug}`} role="button" className={`btn btn-sm text-white`}
                              style={{backgroundColor: news.category?.background_color?.replaceAll('##', '#'), color: news.category?.font_color?.replaceAll('##', '#')}}>
                            <HTMLEllipsis
                                unsafeHTML={news.category?.name || news.slug}
                                maxLine='4'
                                ellipsis='...'
                                trimright={'true'}
                                style={{backgroundColor: news.category?.background_color, color: news.category?.font_color}}
                            />
                        </Link>
                    </Card.Text>
                </Card.ImgOverlay>

                {
                    isNewsOverImage &&
                    <>
                        <Card.ImgOverlay className={'d-flex align-items-start flex-column justify-content-end'}>
                            <Card.Text className={`sora text-white font-size-10`}>
                                <HTMLEllipsis
                                    unsafeHTML={`${news.publisher?.name || news.title.substring(0, 5)} - ${moment(news.publisher?.published_at || new Date()).format('D MMM YYYY')}`}
                                    maxLine='2'
                                    ellipsis='...'
                                    trimright={'true'}
                                />
                            </Card.Text>

                            <Card.Text className={`font-600 sora text-white ${isMobile ? "font-size-12" : "font-size-16"}`}>
                                <HTMLEllipsis
                                    unsafeHTML={`${news.title}`}
                                    maxLine='2'
                                    ellipsis='...'
                                    trimright={'true'}
                                    basedOn='words'
                                />
                            </Card.Text>
                        </Card.ImgOverlay>
                    </>
                }
                {isNewsBelow && newsBelowImage()}
            </Card>
        )
    }

    const newsBelowImage = () => {
        return (
            <div className={`d-flex flex-column py-3`}>
                <span className={`font-700 ${isMobile ? "font-size-14" : "font-size-16"} ${isMobile ? "" : 'mb-4'} mt-2 fontColor `}>
                    <HTMLEllipsis
                        unsafeHTML={`${news.title}`}
                        maxLine='5'
                        ellipsis='...'
                        trimright={'true'}
                        basedOn='letters'
                    />
                </span>
                <span className={`font-size-12 ${isMobile && 'mt-1'} ${isMobile && 'mb-5'} text-grey`}>
                    {news.publisher?.name || news.title.substring(0, 5)} - {moment(news.publisher?.published_at || new Date()).format('D MMM YYYY')}
                </span>
            </div>
        )
    }


    const newsSideImage = () => {
        let className = `${newsCard}Styles`;

        return (
            <div className={`${isMobile ? "" : "d-flex"} mb-1  ${isMobile ? "" : "ms-2"}  col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12`}>
                <Image
                    src={news.source_image}
                    alt={news.title}
                    className={className}
                    style={{height: props.height || 'auto'}}
                />

                <div className={`d-flex flex-column mb-1  ${isMobile ? "" : "ms-2"} col-sm-12 col-xs-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9`}>
                    <span className={`font-700 ${isMobile ? "font-size-12" : "font-size-14"}  ${isMobile ? "" : 'mb-4'} fontColor`}>
                        <HTMLEllipsis
                            unsafeHTML={`${news.title}`}
                            maxLine='2'
                            ellipsis='...'
                            trimright={'true'}
                        />
                    </span>


                    <span className={`font-size-12 text-grey  ${isMobile && 'mt-1'} ${isMobile && 'mb-3'}`}
                          dangerouslySetInnerHTML={{__html: (news.publisher?.name || news.title.substring(0, 5))}}
                    />
                    <span className={`font-size-12 text-grey  ${isMobile && 'mt-1'} ${isMobile && 'mb-3'}`}
                          dangerouslySetInnerHTML={{__html: (moment(news.publisher?.published_at || new Date()).format('D MMM YYYY'))}}
                    />
                </div>
            </div>
        )
    }

    const renderMain = () => {
        return (
            <>
                <Link to={`/news/${news.category.slug}/${news.slug}`} className={`text-decoration-none ${isNewsOverImage ? 'text-white' : 'text-black'} w-100`}>
                    {
                        isNewsSide ? newsSideImage() : renderNewsOverImage()
                    }
                </Link>
            </>
        )
    }

    return news && renderMain();
}

NewsCard.propTypes = {
    news: PropTypes.object.isRequired,
    newsCard: PropTypes.string.isRequired,
}

export default NewsCard
