import Marquee from "react-fast-marquee";
import axios from "axios";
import { useEffect, useState } from "react";
import { ClientsAPI } from "../../Axios/EndPoints";
import customizeWithEase from "../../assets/images/customizeWithEase.png";
import perfectlyResponsive from "../../assets/images/perfectlyResponsive.png";
import friendlySupport from "../../assets/images/friendlySupport.png";

export const BenefitComponent = () => {
    const [companies, setCompanies] = useState([]);
    const [count, setCount] = useState(0);

    const fetchCompanies = () => {
        axios
            .get(`${ClientsAPI}`)
            .then((companyLogo) => {
                setCompanies(
                    companyLogo.data.response.count > 0
                        ? companyLogo.data.response.logo
                        : []
                );
                setCount(companyLogo.data.response.count);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <div className={`container mt-5 pt-5 pb-5`}>
            <div className={`row`}>
                <div
                    className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-column justify-content-center col-sm-mb-3`}
                >
                    <div
                        className={`bebas font-500 font-size-30 text-decoration-underline text-center`}
                    >
                        The Benefits of working with us
                    </div>
                    <div className={`container`}>
                        <div
                            className={`row flex-row justify-content-end mt-5 mb-5 mt-sm-5 `}
                        >
                            <div
                                className={`col-xl-4 col-lg-4 col-md-4 col-sm-12  d-flex flex-column align-items-stretch`}
                            >
                                <div
                                    className={`col d-flex flex-column align-items-stretch bg-white border border-white m-2 p-5`}
                                >
                                    <div className={`col-1`}></div>
                                    <div>
                                        <img src={customizeWithEase} className={"my-2"} />
                                    </div>

                                    <div className={`sora font-600 font-size-20 my-3`}>
                                        Customize with Ease
                                    </div>
                                    <div className={`sora font-size-14 font-400`}>
                                        With our hand-writen reusable code to make your dream
                                        website, customizing it has never been more easier. Make
                                        your business better than your competition!
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col-xl-4 col-lg-4 col-md-4 col-sm-12  d-flex flex-column align-items-stretch`}
                            >
                                <div
                                    className={`col d-flex flex-column align-items-stretch bg-white border border-white m-2 p-5`}
                                >
                                    <div className={`col-1`}></div>
                                    <div>
                                        <img src={perfectlyResponsive} className={"my-2"} />
                                    </div>
                                    <div className={`sora font-600 font-size-20 my-3`}>
                                        Perfectly Responsive
                                    </div>
                                    <div className={`sora font-size-14 font-400`}>
                                        Not only will we make your website perfect on desktops, your
                                        website will be perfectly responsive to mobile screens.
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col-xl-4 col-lg-4 col-md-4 col-sm-12  d-flex flex-column align-items-stretch`}
                            >
                                <div
                                    className={`col d-flex align-items-stretch flex-column bg-white border border-white m-2 p-5`}
                                >
                                    <div className={`col-1`}></div>
                                    <div>
                                        <img src={friendlySupport} className={"my-2 img-fluid"} />
                                    </div>
                                    <div className={`sora font-600 font-size-20 my-3`}>
                                        Friendly Support
                                    </div>
                                    <div className={`sora font-size-14 font-400`}>
                                        Our friendly support will be with you before the start of
                                        the project till the project ends. We are passionate about
                                        creating valuable products and helping you climb the ladder
                                        of technological development.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`row flex-row justify-content-center mt-5`}>
                        <div className={`col-xl-2 col-lg-2 col-md-2 col-sm-12`}>
                            <div className={`row text-start`}>
                                <div className={`col-3`}>
                                    <span className={`bebas text-pink font-size-40 font-800`}>
                                        {count}+
                                    </span>
                                </div>
                                <div className={`col`}>
                                    <span className={`sora text-xiketic font-size-12`}>
                                        Satisfied
                                        <br />
                                        Clients
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`col-xl-9 col-lg-9 col-md-9 col-sm-12`}>
                            <Marquee pauseOnHover={true} gradient={false}>
                                {companies.map((companyLogo, index) => {
                                    return (
                                        <div className={`d-flex me-4`} key={`client_${index}`}>
                                            <img
                                                src={companyLogo}
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    height: "50px",
                                                }}
                                                alt={"Code Studio Client"}
                                            />
                                        </div>
                                    );
                                })}
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
