import {GetQuoteButton} from "../GetQuoteButton";

export const BannerComponent = () => {
    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <div className={'banner-text'}>
                    <div className={`container mt-5 pt-5 pb-5`}>
                        <div className={`row`}>
                            <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-column justify-content-center text-center`}>
                                <div className={`bebas font-500 font-size-40 text-decoration-underline`}>
                                    Services We Offer
                                </div>
                                <div className={`sora font-400 font-size-20 mb-4`}>
                                    Our Service include the following but are not limited to
                                </div>
                                <div className={`sora`}>
                                    <img
                                        src={require('../../assets/images/resized/Servcies---Banner.png')}
                                        alt={'Code Studio Services'}
                                        // height={'450px'}
                                        width={'100%'}
                                        style={{
                                            objectFit: "cover"
                                        }}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <GetQuoteButton/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}