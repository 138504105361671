import {GetQuoteButton} from "../GetQuoteButton";

export const AboutComponent = () => {
    return (
        <div className={`container mt-5 pt-5 pb-5 p-lr-20`}>
            <div className={`row`}>
                <div className={`col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center`}>
                    <div className={`sora font-400 font-size-12 text-pink mb-4`}>
                        About Us
                    </div>
                    <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                        OUR MISSION IS TO HARNESS OUR CREATIVITY INTO A VIABLE SOLUTION FOR YOUR BUSINESS.
                    </div>
                    <div className={`sora`}>
                        <br />
                        Back in 2017, Code Studio started out as a small one room company working on website and software development. With an ambition to grow global, we’re now a team of skilled professionals catering to both National and International clients. In addition to a growing team, our core principles on how we work has remained the same.
                        <br />
                        <br />
                        <ul className={``}>
                            <li>Sincerity to our clients.</li>
                            <li>Astonishing Design for your brand identity.</li>
                            <li>Dedication towards completing your dream project.</li>
                            <li>Specialising in end to end website and application development.</li>
                            <li>Idiosyncratic Ideas.</li>
                            <li>Work completed timely based on your needs.</li>
                        </ul>
                    </div>
                    <div className={``}>
                        <GetQuoteButton/>
                    </div>
                </div>
                <div className={`offset-xl-2 offset-lg-2 offset-md-2 offset-sm-9 col-xl-3 col-lg-3 col-md-3 col-sm-12 text-end`}>
                    <img src={require('../../assets/images/resized/Our-Mission---About-Us.png')} alt={'About Code Studio'} className={`img-fluid`}/>
                </div>
            </div>
        </div>
    )
}
