import React, {useEffect, useState} from 'react';
import {Col, Container} from 'react-bootstrap';
import MarqueeEffect from '../MarqueeEffect/MarqueeEffect';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const BreakingNews = (props) => {

    const {news} = props;

    let [isMobile, setIsMobile] = useState(false);

    let SCREEN_SIZE = {
        'MOBILE': 600,
        'TABLET': 786,
        'LAPTOP': 1024,
        'LAPTOP_LG': 1400,
        'LAPTOP_XL': 1900,
        'LAPTOP_XXL': 2160,
    };

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    const render = () => {
        return (
            <div className={`w-100 bg-pink `}>
                <Col className={`px-4 ${isMobile && 'py-2'} `} xs={12} md={12} lg={12} xl={12} xxl={12}>
                    <Container className={'marquee-padding'}>
                        <MarqueeEffect>
                            <span className={`sora font-700 text-white ms-4 me-4 ${isMobile ? 'font-size-14' : 'fs-5'}`}>Breaking News: </span>
                            <Link to={`/news/${news?.category.slug}/${news?.slug}`} className={`text-decoration-none`}>
                                <span className={`sora text-white ${isMobile ? 'font-size-14' : 'fs-5'}`} dangerouslySetInnerHTML={{__html: news?.title}}/>
                            </Link>
                        </MarqueeEffect>
                    </Container>
                </Col>
            </div>
        );
    }

    return news ? render() : <></>;
};

BreakingNews.propTypes = {
    news: PropTypes.object.isRequired
}

export default BreakingNews;
