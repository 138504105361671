import AllCategoryVideos from "../Components/Videos/AllCategoryVideos";
import FeaturedVideos from "../Components/Videos/FeaturedVideos";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {get} from "../Axios/get";
import {NewsApi, VideosApi} from "../Axios/EndPoints";
import LatestPosts from "../Components/Videos/LatestPosts";
// import RecommendedForYou from "../Components/Videos/RecommendedForYou";
import RecommendedForYouVideos from "../Components/Videos/RecommendedForYouVideos";
import GoogleAd from "../Components/GoogleAd";

const Videos = () => {
    const {category} = useParams();
    const ref = useRef()
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isItemLoading, setItemLoading] = useState(true);
    const [latestNews, setLatestNews] = useState([]);
    const [moreNews, setMoreNews] = useState([]);
    const [featuredNews, setFeaturedNews] = useState([]);
    const [apiPage, setApiPage] = useState(1);
    const [restrictApiCall, setRestrictApiCall] = useState(true);
    const [recommendedNews, setRecommendedNews] = useState([]);

    let SCREEN_SIZE = {
        'MOBILE': 600,
        'TABLET': 786,
        'LAPTOP': 1024,
        'LAPTOP_LG': 1400,
        'LAPTOP_XL': 1900,
        'LAPTOP_XXL': 2160,
    };

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        setLatestNews([]);
        setMoreNews([]);
        setRestrictApiCall(true);
        setLoading(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        fetchLatestNews(21, true);
        !category && fetchFeaturedNews();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [category]);

    useEffect(() => {
        if (category) {
            !restrictApiCall && fetchLatestNews();
        }
    }, [apiPage, restrictApiCall]);

    useEffect(() => {
        fetchRecommendedNews();
    }, []);

    useEffect(() => {
    }, [ref]);


    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight - document.documentElement.clientHeight - 10 <= document.documentElement.scrollTop;
        if (pageBottom) {
            category && setItemLoading(true);
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchFeaturedNews = () => {
        let categorySlug = '';
        if (category) {
            categorySlug = category;
        }
        let parameters = '?limit=4&featured=true';
        get(VideosApi + categorySlug + parameters)
            .then((response) => {
                setFeaturedNews(response.data.response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
            });
    }

    const fetchLatestNews = (limit = 21, isNew = false) => {
        let categorySlug = '';
        if (category) {
            categorySlug = category;
            limit = 45;
        }
        let parameters = `?page=${apiPage}&limit=${limit}&latest=true`;
        if (category === 'featured') {
            parameters = `?page=${apiPage}&limit=${limit}&featured=true`;
        }
        get(VideosApi + categorySlug + parameters)
            .then((response) => {
                if (isNew) {
                    setLatestNews(response.data.response);
                    setMoreNews(response.data.response.slice(5, -1));
                } else {
                    setMoreNews([...moreNews, ...(response.data.response)]);
                }
                setLoading(false);
                setItemLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
                setItemLoading(false);
            });
    }
    const fetchRecommendedNews = () => {
        let parameters = '?limit=15&recommended=true';
        get(VideosApi + parameters)
            .then((response) => {
                setRecommendedNews(response.data.response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
            });
    }

    const renderMain = () => {
        return (
            <>
                <AllCategoryVideos isMobile={isMobile} latestNews={latestNews}/>
                {!category && (<>
                    <FeaturedVideos latestNews={latestNews} isMobile={isMobile}/>
                     <RecommendedForYouVideos recommendedNews={recommendedNews} isMobile={isMobile} ref={ref}/>
                    <GoogleAd id={'9462835128'}/>
                </>)}
                <LatestPosts latestNews={moreNews} isMobile={isMobile} showHeading={!category}/>
            </>
        )
    }

    return renderMain()
}

export default Videos;
