import NumberOfPackagePoints from "./NumberOfPackagePoints";
import Bronze from "../../assets/images/Bronze.png";
import PackageName from "./PackageName";
import PackagePrice from "./PackagePrice";
import PinkCorner from "./PinkCorner";

const FirstPackage = (props) => {
  const { isMobile, darkBg } = props;

  const renderMain = () => {
    return (
      <div
        className={
          "d-flex flex-column position-relative h-100 justify-content-between"
        }
      >
        <div className={"d-flex flex-column"}>
          {!isMobile ? (
            <PackageName
              isMobile={isMobile}
              packageName={"BRONZE"}
              imgName={Bronze}
            />
          ) : null}

          <div className={"d-flex align-items-center mt-3 "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Photo Posts Per Week"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Stories Per Week"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"6 Reels Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"1 Videos Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"3 Restaurant Listing Websites"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"2 Facebook Ad Campaigns Per Month"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Facebook Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Instagram Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Facebook Ads Account Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Google Business Profile Setup"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Social Media Audit (Basic)"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Account Manager (Shared)"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Weekly Report"}
            />
          </div>

          <div className={"d-flex align-items-center "}>
            <PinkCorner />
            <NumberOfPackagePoints
              isMobile={isMobile}
              firstPackage={true}
              point={"- Support Within 24 Hours"}
            />
          </div>
          <hr
            className={`customHR first-hr-gradient ms-4`}
            style={{ width: "90%" }}
          />
        </div>
        <div className={`${isMobile ? "mt-5" : null}`}>
          <PackagePrice
            isMobile={isMobile}
            oldPrice={"£250"}
            newPrice={"199"}
            packageName={"BRONZE"}
          />
        </div>
      </div>
    );
  };

  return renderMain();
};

export default FirstPackage;
