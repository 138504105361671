import FirstPackage from "../Components/Packages/FirstPackage";
import ThirdPackage from "../Components/Packages/ThirdPackage";
import SecondPackage from "../Components/Packages/SecondPackage";
import { useState } from "react";
import { useEffect } from "react";
import { SCREEN_SIZE } from "../Constants/ScreenSizes";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Packages = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [key, setKey] = useState("silver");

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  const renderMobileView = () => {
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 px-3"
        fill
        justify
      >
        <Tab
          eventKey="bronze"
          title="BRONZE"
          className={`packages__firstPackage__mobile ${
            isMobile ? "me-2 mb-4 ms-2" : null
          }`}
          style={{ paddingLeft: isMobile ? "12px" : null }}
        >
          <FirstPackage isMobile={isMobile} darkBg={false} />
        </Tab>
        <Tab
          eventKey="silver"
          title="SILVER"
          className={`middle__package ${isMobile ? "mb-4 ms-2 me-2" : null}`}
        >
          <SecondPackage isMobile={isMobile} darkBg={true} />
        </Tab>
        <Tab
          eventKey="gold"
          title="GOLD"
          className={`packages__thirdPackage__mobile ${
            isMobile ? "me-2 mb-4 ms-2" : null
          }`}
        >
          <ThirdPackage
            thirdPackage={true}
            isMobile={isMobile}
            darkBg={false}
          />
        </Tab>
      </Tabs>
    );
  };

  const renderMain = () => {
    return (
      <div className={"container"}>
          <div>
            <h1>Social Media Packages</h1>
          </div>
        <div
          className={`row mt-5 mb-5 ${
            isMobile ? "px-2" : "p-5"
          } justify-content-center`}
        >
          <div
            className={`col-sm-12 col-md-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4
             border border-1 bg-white mb-4 packages__firstPackage`}
            style={{ zIndex: isMobile ? 0 : 1 }}
          >
            <FirstPackage isMobile={isMobile} darkBg={false} />
          </div>
          <div
            className={`${
              isMobile && "mb-4"
            } col-sm-12 col-md-12 bg-violet col-md-4 col-lg-4 col-xl-4 col-xxl-4 border border-1 middle__package`}
            style={{
              zIndex: isMobile ? 0 : 200,
              marginTop: isMobile ? 0 : "-45px",
              marginBottom: isMobile ? 0 : "-25px",
            }}
          >
            <SecondPackage isMobile={isMobile} darkBg={true} />
          </div>
          <div
            className={`col-sm-12 col-md-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 border border-1 packages__thirdPackage mb-4 `}
            style={{ zIndex: isMobile ? 0 : 1 }}
          >
            <ThirdPackage isMobile={isMobile} darkBg={false} />
          </div>
        </div>
      </div>
    );
  };

  return isMobile ? renderMobileView() : renderMain();
};

export default Packages;
