import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {post} from "./Axios/post";
import {STORE_FCM_TOKEN} from "./Axios/EndPoints";
import {getCountryForTimezone} from "countries-and-timezones";

const firebaseConfig = {
    apiKey: "AIzaSyCe6XJz-f0jTpK_BdKZiHHVhb08K88yxAk",
    authDomain: "codestudio-7b234.firebaseapp.com",
    projectId: "codestudio-7b234",
    storageBucket: "codestudio-7b234.appspot.com",
    messagingSenderId: "482922566468",
    appId: "1:482922566468:web:3b7e3c5557492045b33d11",
    measurementId: "G-BFT9137KBL"
};
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const getLocalToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BG1sRsPV5YVUmcAzExqFEO1RqBCdiV-VgKqwVmJu-B_P5AUGTiAHgsooGwNHSZE3E0bT6rJ9os053Cx2tODld3Y'}).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            setTokenFound(true);
            return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            // console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
