import getQuoteImg from '../../assets/images/When you work with us, you get.jpg';
import {FormControl} from "react-bootstrap";
import {useEffect, useState} from "react";
import validator from "validator/es";
import axios from "axios";
import {ContactUsAPI} from "../../Axios/EndPoints";

export const GetQuoteComponent = () => {

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [isPostSuccess, setIsPostSuccess] = useState(null);

    const handleSubmitClick = () => {
        let data = null;

        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }

        if (canBeSubmitted) {
            data = {
                name: name,
                email: email,
                phone: phone,
            };
            postData(data);
        }
    }

    const postData = (data) => {
        axios.post(ContactUsAPI, data).then((response) => {
            if (response.data.success) {
                setIsPostSuccess('success');
            } else {
                setIsPostSuccess('danger');
            }
        })
    }

    useEffect(() => {
    }, [errorName, errorEmail, errorPhone]);


    return (
        <div className={`container mt-5 mb-5 pt-5 pb-5`}>
            <div className={`row`}>
                <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-5`}
                     style={{
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                         height: 'auto',
                         backgroundImage: `url('${getQuoteImg}')`,
                     }}>
                    <div className={`text-white bebas font-500 font-size-30 mt-5 pt-5 ps-5`}>
                        Thank you for your interest in our Products and Services.
                    </div>
                    <div className={`text-white sora font-400 font-size-16 mt-5 pt-5 ps-5 pb-5 mb-5`}>
                        We seek to create long-term relationships which are built on results. Our aim is to build your online presence by providing you the best digital solution package possible.
                    </div>
                </div>
                <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 bg-pink p-5`}>
                    <div className={`offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 col-xl-8 col-lg-8 col-md-8 col-sm-10`}>
                        <div className={`text-white bebas font-500 font-size-30 ps-5`}>
                            Request a Quote
                        </div>
                        <div className={`text-white sora font-400 font-size-16 pt-4 ps-5 pb-5`}>
                            We seek to create long-term relationships which are built on results. Our aim is to build your online presence by providing you the best digital solution package possible.
                            Reach out by filling the form below and let’s talk!
                        </div>
                        <div className={`text-white sora font-400 font-size-16 ps-5 pb-5`}>
                            <FormControl
                                isInvalid={errorName}
                                type="text"
                                id="name"
                                aria-describedby="name"
                                placeholder="Your Name"
                                className={`custom-text-input mb-3`}
                                value={name}
                                onChange={(e) => {
                                    setErrorName(false);
                                    setName(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4 text-white`}>Please input name</FormControl.Feedback>
                            <FormControl
                                isInvalid={errorEmail}
                                type="text"
                                id="email"
                                aria-describedby="email"
                                placeholder="Your Email"
                                className={`custom-text-input mb-3`}
                                value={email}
                                onChange={(e) => {
                                    setErrorEmail(false);
                                    setEmail(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4 text-white`}>Please input email</FormControl.Feedback>
                            <FormControl
                                isInvalid={errorPhone}
                                type="text"
                                id="number"
                                aria-describedby="number"
                                placeholder="Contact Number"
                                className={`custom-text-input mb-3`}
                                value={phone}
                                onChange={(e) => {
                                    setErrorPhone(false);
                                    setPhone(e.target.value);
                                }}
                            />
                            <FormControl.Feedback type={'invalid'} className={`mb-4 text-white`}>Please input phone</FormControl.Feedback>
                            <button className={`btn btn-violet w-100 rounded-0 bebas font-600 font-size-20`} onClick={handleSubmitClick}>
                                Submit
                            </button>
                            {
                                isPostSuccess !== null && (
                                    <div className={`alert alert-${isPostSuccess} alert-dismissible`}>
                                        {
                                            isPostSuccess === 'success' ?
                                                'Successfully submitted' :
                                                'Some error occurred. Please try again later'
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}