import React, {useEffect, useState} from 'react';
import {Button, Container} from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link, useParams} from 'react-router-dom';
import axios from "axios";
import {NewsCategoriesApi} from "../../../Axios/EndPoints";
import {SCREEN_SIZE} from "../../../Constants/ScreenSizes";

const NewsNavigation = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    const [newsCategories, setNewsCategories] = useState([]);
    const {category} = useParams();

    const fetchCategories = () => {
        axios.get(NewsCategoriesApi)
            .then((response) => {
                setNewsCategories(response.data.response);
            });
    }

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
        fetchCategories();
    }, [window.innerWidth]);


    const RightArrow = ({className, style, onClick}) => (
        <Button style={{...style, right: 0, cursor: 'pointer'}} onClick={onClick} className={className}>
            <span>back</span>
        </Button>
    );

    const LeftArrow = ({className, style, onClick}) => (
        <Button style={{...style, left: 0, cursor: 'pointer'}} onClick={onClick} className={className}>
            <div>back</div>
        </Button>
    );


    const settings = {
        infinite: false,
        speed: 200,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <LeftArrow/>,
        nextArrow: <RightArrow/>,
        centerMode: false,
        centerPadding: 0,
        dots: false,
        variableWidth: true,
        useCSS: true,
        useTransform: true,
        className: "slider variable-width",
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    infinite: false,
                    centerMode: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false,
                    scrollable: true,
                    draggable: true,
                    swipeToSlide: true,
                    useCSS: true,
                    useTransform: true,
                }
            }
        ],
    };

    return (
        <Container className={`navbar-transparent text-start justify-content-start`}>
            <Slider  {...settings} style={{padding: '25px'}} className={`text-start justify-content-start`}>
                <div className={'d-flex justify-content-center'}>
                    <Link to={`/${props.where || 'news'}`}
                          className={`${props.bg === 'dark' ? 'text-white pipe-white' : 'nav-link pipe-black'} ${isMobile ? 'font-size-16' : 'fs-5'} sora
                          ${typeof category === 'undefined' ? 'active' : ''}`}>
                        Home
                    </Link>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <Link to={`/${props.where || 'news'}/featured`}
                          className={`${props.bg === 'dark' ? 'text-white pipe-white' : 'nav-link pipe-black'} ${isMobile ? 'font-size-16' : 'fs-5'} sora
                          ${category === 'featured' ? 'active' : ''}`}>
                        Featured
                    </Link>
                </div>

                {
                    newsCategories.map((newsCategory, index) => (
                        <div className={'d-flex justify-content-center text-nowrap'} key={`news_category_${newsCategory.id}`}>
                            <Link to={`/${props.where || 'news'}/${newsCategory.slug}`}
                                  className={`${props.bg === 'dark' ? (index + 1 < newsCategories.length ? 'text-white pipe-white' : 'text-white') :
                                      (index + 1 < newsCategories.length ? 'nav-link pipe-black' : 'nav-link')} 
                                  ${isMobile ? 'font-size-16' : 'fs-5'} sora ${category === newsCategory.slug ? 'active' : ''}`}>
                                {newsCategory.name}
                            </Link>
                        </div>
                    ))
                }

            </Slider>
        </Container>
    );
};

export default NewsNavigation;
