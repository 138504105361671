import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

const SiteMetaHelmet = (props) => {
    return (
        <Helmet>
            <title>Code Studio: {props.title}</title>

            <meta name="name" content={`${props.title}`}/>
            <meta name="image" content={`${props.image}`}/>
            <meta name="description" content={`${props.description}`}/>

            {/*Facebook Meta Tags*/}
            <meta property="og:url" content={`${props.url}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={`${props.title}`}/>
            <meta property="og:description" content={`${props.description}`}/>
            <meta property="og:image" content={`${props.image}`}/>
            {/*Twitter Meta Tags*/}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={`${props.title}`}/>
            <meta name="twitter:description" content={`${props.description}`}/>
            <meta name="twitter:image" content={`${props.image}`}/>
        </Helmet>
    )
}

SiteMetaHelmet.propTypes= {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

export default SiteMetaHelmet;
