import GetQuotePopup from "./Popovers/GetQuotePopup";
import {useState} from "react";

export const GetQuoteButton = () => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <button className={'btn btn-violet rounded-0 bebas font-size-30 font-500 text-nowrap'}
                    onClick={() => setShowModal(true)}
            >
                FREE Quote
            </button>
            <GetQuotePopup
                show={showModal}
                setShowModal={setShowModal}
                onHide={() => setShowModal(false)}/>
        </>
    )
}
