import {GetQuoteButton} from "../GetQuoteButton";
import {useEffect, useState} from "react";

export const BannerComponent = (props) => {

    const [item,] = useState(props.portfolio);

    useEffect(() => {
    }, [props.portfolio]);

    const render = () => {
        return (
            <div className={'banner'}>
                <div className={'banner-image pb-5'}>
                    <div className={'banner-text pb-5'}>
                        <div className={'container p-lr-20'}>
                            <div className={`sora font-600 font-size-24`} style={{ color: '#282938' }}>
                                {item.categories.toString()}
                            </div>
                            <div className={`bebas font-500 font-size-50 mt-4`}>
                                {item.name}
                            </div>
                            <div className={`sora font-400 font-size-18 mt-4`}>
                                {item.short_description}
                            </div>
                            <div className={`row text-center mt-5`}>
                                <img src={item.thumbnail} alt={`Code Studio - ${item.name} - ${item.categories.toString()}`} className={`img-fluid`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}