import React, {useEffect, useState} from 'react';
import NewsNavigation from '../Components/News/Navigation/NewsNavigation';
import BreakingNews from '../Components/News/BreakingNews';
import NewsCard from "../Components/News/NewsCard";
import InnovationImage from '../assets/images/Innovation.png'
import RecommendedForYou from "../Components/News/RecommendedForYou/RecommendedForYou";
import {SCREEN_SIZE} from "../Constants/ScreenSizes";
import {get} from "../Axios/get";
import {NewsApi} from "../Axios/EndPoints";
import {Link, useParams} from "react-router-dom";
import PageLoader from "../Components/Loaders/PageLoader";
import GoogleAd from "../Components/GoogleAd";
import ItemLoader from "../Components/Loaders/ItemLoader";

const News = () => {
    const {category} = useParams();
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isItemLoading, setItemLoading] = useState(true);
    const [latestNews, setLatestNews] = useState([]);
    const [moreNews, setMoreNews] = useState([]);
    const [featuredNews, setFeaturedNews] = useState([]);
    const [apiPage, setApiPage] = useState(1);
    const [restrictApiCall, setRestrictApiCall] = useState(true);

    const fetchLatestNews = (limit = 21, isNew = false) => {
        let categorySlug = '';
        if (category) {
            categorySlug = category;
            limit = 45;
        }
        let parameters = `?page=${apiPage}&limit=${limit}&latest=true`;
        if (category === 'featured') {
            parameters = `?page=${apiPage}&limit=${limit}&featured=true`;
        }
        get(NewsApi + categorySlug + parameters)
            .then((response) => {
                if (isNew) {
                    setLatestNews(response.data.response);
                    setMoreNews(response.data.response.slice(4, -1));
                } else {
                    setMoreNews([...moreNews, ...(response.data.response.slice(4, -1))]);
                }
                setLoading(false);
                setItemLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
                setItemLoading(false);
            });
    }
    const fetchFeaturedNews = () => {
        let categorySlug = '';
        if (category) {
            categorySlug = category;
        }
        let parameters = '?limit=4&featured=true';
        get(NewsApi + categorySlug + parameters)
            .then((response) => {
                setFeaturedNews(response.data.response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
            });
    }
    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight - document.documentElement.clientHeight - 10 <= document.documentElement.scrollTop;
        if (pageBottom) {
            category &&  setItemLoading(true);
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.TABLET) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        setLatestNews([]);
        setMoreNews([]);
        setRestrictApiCall(true);
        setLoading(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        fetchLatestNews(21, true);
        !category && fetchFeaturedNews();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [category]);

    useEffect(() => {
        if (category) {
            !restrictApiCall && fetchLatestNews();
        }
    }, [apiPage, restrictApiCall]);

    const renderAllNews = () => {
        return (<section className={'container mt-5'}>
            <div className={`row ${isMobile && 'ms-auto'}`}>
                <h1 className={'text-pink bebas'}>Latest News</h1>
                <div className={'col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5'}>
                    <div className={'row'}>
                        <div className={`col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 ${isMobile ? "" : 'me-1'}`}>
                            <NewsCard
                                news={latestNews[0]}
                                isLargeImage={false}
                                isNewsOverImage={true}
                                bgColor={'bg-pink'}
                                newsCard={"newsCardOne"}
                            />
                        </div>
                        <div className={`col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 ${isMobile ? "" : 'ms-5'} ${isMobile && 'pt-2'}`}>
                            <NewsCard
                                news={latestNews[1]}
                                isLargeImage={false}
                                isNewsOverImage={true}
                                newsCard={"newsCardOne"}
                                bgColor={'bg-pink'}
                            />
                        </div>
                    </div>


                    <div className={'pt-2 row'}>
                        <div className={'col-12'}>
                            <NewsCard
                                news={latestNews[2]}
                                isLargeImage={false}
                                isNewsOverImage={true}
                                bgColor={'bg-pink'}
                                newsCard={"newsCardTwo"}
                            />
                        </div>
                    </div>
                </div>

                <div className={`col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ${isMobile ? "" : "ms-3"} ${isMobile && "pt-2"}`}>
                    <NewsCard
                        news={latestNews[3]}
                        isLargeImage={false}
                        isNewsOverImage={true}
                        bgColor={'bg-blue'}
                        newsCard={"newsCardThree"}
                    />
                </div>

            </div>
        </section>)
    }

    const renderAllCategoryNewsAtTop = () => {
        return (
            <div className={`w-100 bg-light-pink-gradient mt-5`}>
                <NewsNavigation bg={'light'}/>
                <BreakingNews news={latestNews[0] || null}/>
                {isMobile && <div className={'col-12'} style={{height: '50px'}}><GoogleAd id={'9462835128'} minHeight={'50px'}/></div>}
                <div className={`${isMobile ? "" : "container-fluid"} bg-light-pink-gradient mt-5`}>
                    {latestNews.length ? renderAllNews() : <></>}
                    <GoogleAd id={'2122836505'} format={'autorelaxed'}/>
                </div>
            </div>
        )
    }

    const renderFeaturedNewsTextAndButton = () => {
        return (<div className={'row mt-5 padding-right-zero'}
                     style={{
                         paddingTop: "5rem",
                     }}
        >
            <div className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 padding-right-zero'}>
                <div className={'d-flex justify-content-between mb-5 pb-5 align-items-center'}>
                    <span className={`${isMobile ? "font-size-12" : "font-size-30 text-dark-blue"} bebas font-500 text-decoration-underline`}>
                        Featured News
                    </span>
                    <Link to={'/news/featured'} className={'btn btn-violet rounded-0 border-0 btn btn-primary py-1 px-3'}>
                        View All
                    </Link>
                </div>
            </div>
        </div>)
    }

    const renderFeaturedNews = () => {

        if (featuredNews.length <= 0) {
            return <></>;
        }

        return (<section className={'container bg-white mb-5'}>
            <div className={`row ${isMobile && 'ms-auto'}`}>
                {isMobile ? "" : renderFeaturedNewsTextAndButton()}
                <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12`}>
                    <div className={'row'}>
                        <div className={'col-sm-12 col-xs-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7'}>
                            <NewsCard
                                news={featuredNews[0]}
                                isMobile={isMobile}
                                isNewsBelow={true}
                                imageURL={InnovationImage}
                                color={'text-dark-blue'}
                                newsCard={"newsCardFour"}
                            />
                        </div>

                        <div className={`${isMobile ? "" : "ms-2"} col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4`}>
                            <NewsCard
                                news={featuredNews[1]}
                                isMobile={isMobile}
                                isNewsBelow={false}
                                color={'text-dark-blue'}
                                newsCard={'newsCardFive'}
                                isNewsSide={true}
                            />

                            <NewsCard
                                news={featuredNews[2]}
                                isMobile={isMobile}
                                isNewsBelow={false}
                                isNewsSide={true}
                                color={'text-dark-blue'}
                                newsCard={'newsCardFive'}
                            />

                            <NewsCard
                                news={featuredNews[3]}
                                newsCard={'newsCardFive'}
                                isMobile={isMobile}
                                isNewsBelow={false}
                                isNewsSide={true}
                                color={'text-dark-blue'}
                            />
                        </div>
                    </div>

                </div>
                <div className={'padding-left-zero'}>
                    {isMobile && <Link to={'/news/featured'} className={`ms-2 mt-3 ${isMobile && "margin-left-12"} btn btn-violet rounded-0 btn btn-primary py-1 px-3`}>View More</Link>}
                </div>
            </div>
            <GoogleAd id={'9462835128'}/>
        </section>)
    }

    const renderMoreNews = () => {

        if (moreNews.length <= 0) {
            return <></>;
        }
        return (<div className={'container bg-white mb-5'}>
            {!category && <p className={`${isMobile ? "font-size-24" : "font-size-30"} ${isMobile && "ms-2"} text-dark-blue mb-4 bebas font-500 text-decoration-underline`}>More News</p>}
            <div className={`row ${isMobile && 'ms-auto'}`}>
                {moreNews.map((value, index) => {
                    return (
                        <>
                            <div className={`col-xs-6 col-sm-6 col-md-6 col-lg-4  col-xl-4 col-xxl-4`} key={`more_news_${value.id}`}>
                                <NewsCard
                                    news={value}
                                    isMobile={isMobile}
                                    isNewsBelow={true}
                                    imageURL={InnovationImage}
                                    color={'text-dark-blue'}
                                    newsCard={"newsCardSeven"}
                                />
                            </div>
                            {(index + 1) % 15 === 0 ? <div className={'col-12 ad-height'} style={{height: '250px'}}><GoogleAd id={'9462835128'}/></div> : <></>}
                        </>
                    );
                })}
                <ItemLoader loading={isItemLoading}/>
            </div>
        </div>)
    }

    const render = () => {
        return (<>
            <div className={`${isMobile ? "" : "w-100"}`}>
                {renderAllCategoryNewsAtTop()}
                {!category && (<>
                    {renderFeaturedNews()}
                    <RecommendedForYou/>
                    <GoogleAd id={'9462835128'}/>
                </>)}
                {renderMoreNews()}
            </div>
        </>);
    }

    return isLoading ? (<PageLoader/>) : render();
};

export default News;
