import Modal from 'react-bootstrap/Modal';
import './CenterPopup.css'


export default function CenterPopup(props) {
    return (
        <div>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={'text-center rounded-0'}
            >
                <Modal.Header closeButton className={'border-0'}>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={props.imgsrc}
                        style={{
                            objectFit: "cover"
                        }}
                        className={'img-fluid'}
                        alt={props.heading}/>
                    <Modal.Title id="contained-modal-title-vcenter" className={'px-2 text-center bebas font-size-40 font-600 text-decoration-underline'}>
                        {props.heading}
                    </Modal.Title>
                    <p className={'mx-auto pt-3 font-size-14 d-flex justify-content-center lh-3 font-300'}>
                        {props.body}
                    </p>
                    <p className={'mx-auto font-size-14 d-flex justify-content-center font-300'}>
                        {props.body1}
                    </p>
                </Modal.Body>
            </Modal>
        </div>
    );
}


