import './../assets/css/index.css';
import {BannerComponent} from "../Components/ServicesDetail/BannerComponent";
import {ServicesComponent} from "../Components/ServicesDetail/ServicesComponent";
import {GetQuoteButton} from "../Components/GetQuoteButton";
import Packages from "./Packages";

export const ServicesDetail = () => {

    const render = () => {
        return (
            <div className={`col`}>
                <BannerComponent/>
                <div className={`pt-5 pb-5`}>
                    <ServicesComponent/>
                </div>
                <div className={'offset-4 col-4 text-center mt-4 mb-5'}>
                    <GetQuoteButton/>
                </div>
                <div className={'text-center mt-4 mb-5'}>
                    <Packages />
                </div>
                <div className={'offset-4 col-4 text-center mt-4 mb-5'}>
                    <GetQuoteButton/>
                </div>
            </div>
        )
    }

    return render();
}
