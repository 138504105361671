import VideoCard from "./VideoCard";
import React from "react";
import GoogleAd from "../GoogleAd";

const LatestPosts = (props) => {
    const {latestNews, isMobile, showHeading} = props;

    console.log('showHeading', showHeading);

    const renderMain = () => {
        return (
            <>
                <div className={"container pt-5"}>
                    <div className={"row justify-content-between"}>
                        <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12"}>
                            <div className={"row justify-content-between align-items-center mb-1"}>
                                <div className={"col"}>
                                    {
                                        !!showHeading && <h1 className={'text-pink bebas'}>MORE Videos</h1>
                                    }
                                </div>
                            </div>
                            <div className={"row mb-5"}>
                                {
                                    latestNews.map((item) => {
                                        return (
                                            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
                                                <VideoCard
                                                    latestSmall={true}
                                                    news={item}
                                                    isLargeImage={false}
                                                    isNewsBelow={true}
                                                    bgColor={'bg-pink'}
                                                    fontSize={12}
                                                    latestPost={true}
                                                    height={isMobile ? "214px" : "321px"}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile &&
                    <div className={'col-12'} style={{height: '50px'}}><GoogleAd id={'9462835128'} minHeight={'50px'}/>
                    </div>}
                {/*<GoogleAd id={'2122836505'} format={'autorelaxed'}/>*/}
            </>
        )
    }

    return renderMain();
}

export default LatestPosts
