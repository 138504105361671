import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";

const GoogleAd = (props) => {

    const banner = useRef();

    const [isError, setIsError] = useState(false);

    const atOptions = {
        key: 'b970341769a3edbd5c722f9d6631466f',
        format: 'iframe',
        height: 50,
        width: 320,
        params: {},
    }
    useEffect(() => {
        if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'codestudionews.com' || window.location.hostname === 'www.codestudionews.com') {
            if (banner.current && !banner.current.firstChild) {
                const conf = document.createElement('script')
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.src = `//www.profitablecreativeformat.com/b970341769a3edbd5c722f9d6631466f/invoke.js`
                conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

                banner.current.append(conf)
                banner.current.append(script)
            }
        }
    }, [banner]);

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            setIsError(false);
        } catch (e) {
            setIsError(true);
        }
    }, [props, isError]);

    const renderGoogleAd = () => {
        if (isError) {
            return (
                <div className={'billboard mb-3'}>
                    <ins className={'adsbygoogle'}
                         style={{
                             display: 'block',
                             textAlign: 'center',
                             minHeight: props.minHeight || '200px',
                             height: props.minHeight || '200px'
                         }}
                         data-ad-client={'ca-pub-2971627968967249'}
                        // data-ad-layout="in-article"
                         data-ad-slot={props.id}
                        // data-ad-format={'auto'}
                        //  data-adtest={'on'}
                         data-full-width-responsive="true"
                    />
                </div>
            );
        }
        return (
            <div className={'billboard mb-3'}>
                <ins className={'adsbygoogle'}
                     style={{
                         display: 'block',
                         textAlign: 'center',
                         minHeight: props.minHeight || '200px',
                         height: props.minHeight || '200px'
                     }}
                     data-ad-client={'ca-pub-2971627968967249'}
                    // data-ad-layout="in-article"
                     data-ad-slot={props.id}
                     data-ad-format={props.format || 'auto'}
                    // data-adtest={'on'}
                     data-full-width-responsive="true"
                />
            </div>
        );
    }

    const renderOtherAd = () => {
        return (
            <>
                <div id="container-f19b38274e8597756040304e78a12667"></div>
                <div className="mx-2 my-5 justify-center items-center text-white text-center"
                     ref={banner}></div>
            </>
        );
    }
    const render = () => {
        if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'codestudionews.com' || window.location.hostname === 'www.codestudionews.com') {
            return renderOtherAd();
        }
        return renderGoogleAd();
    }

    return render();

}

GoogleAd.propTypes = {
    id: PropTypes.string.isRequired,
    format: PropTypes.string
}

export default GoogleAd;
