import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Container } from 'react-bootstrap'

const PortfolioLoader = () => {
    return (
        <Container>
            <div className={'row'}>
                <div className={'col-sm-12 col-md-12'}>
                    <div className={'row'}>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                        <div className={'col-sm-12 col-md-6'}>
                            <Skeleton count={1} height={'500px'} className={'mt-5'} />
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PortfolioLoader