import React from 'react'
import {Card} from 'react-bootstrap'
import servicesDesignLogo from '../../assets/images/services/Design.svg'
import softwareDevelopmentLogo from '../../assets/images/services/web-design.svg'
import smmLogo from '../../assets/images/services/bullhorn 1.svg'
import gdbLogo from '../../assets/images/services/Rectangle 34.svg'
import '../../assets/css/background-colors.css';

export const ServicesComponent = () => {
    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12'}>
                    <div className={'row g-4'}>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md p-3'>
                                <Card.Img variant="top" src={servicesDesignLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Web Design and Development
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        Code Studio with its creative design team, provides matchless web design and development services to a global clientèle from different niches. Our
                                        innovative and ingenious designers and developers adhere to the requirements of the clients and produce designs that are unique and equally befitting to the
                                        needs of our clients. Client’s Satisfaction is our first priority.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md p-3'>
                                <Card.Img variant="top" src={softwareDevelopmentLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Software Development
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        Code Studio will build custom web applications to help you overcome challenges and prepare for the future. Our developers are experts in deploying
                                        innovative React applications through thorough testing. We constantly strive to build products that are efficient and easy to maintain. Psychology driven design
                                        frameworks are employed to give a captivating user-experience.
                                        as fdjasld joa ldjoaldj moald ald ald ald al dak ldal da lda ld
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md mt-5 p-3'>
                                <Card.Img variant="top" src={smmLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Social Media Marketing - Campaigning
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        Code Studio will help your business by building a strong authoritative social media presence. Build a community of loyal community around your business
                                        by utilising the power of social media. Let us help you increase your brand awareness and establish your most important metrics as we analyze your business
                                        competition.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md mt-5 p-3'>
                                <Card.Img variant="top" src={gdbLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Graphic Design and Branding
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        Code Studio will give your brand its own identity and a unique style with our leading graphic design and branding experts. Showcase your business with
                                        a unique brand identity as you standout from the competition. Make your first impression with your customers which will last forever. Let us help your brand and
                                        business by creating its identity.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md mt-5 p-3'>
                                <Card.Img variant="top" src={softwareDevelopmentLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Domain Management
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        Code Studio will keep the ongoing tasks of keeping your domain stable, secure and able to support related websites. Whether you’re a small business, or
                                        a large corporation, managing your domain portfolio is as important as managing your website. With sophisticated analytics for tracking unauthorized uses, and
                                        other key metrics, let us do the work that you never have to deal with.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-items-stretch d-flex'}>
                            <Card className='rounded-0 border-0 shadow-md mt-5 p-3'>
                                <Card.Img variant="top" src={servicesDesignLogo}
                                          style={{
                                              objectFit: "cover",
                                              width: "20%",
                                              height: "auto"
                                          }}
                                          className={'p-3'}
                                />
                                <Card.Body>
                                    <Card.Title className={'bebas font-size-30 font-500 text-left text-decoration-underline'}>
                                        Mobile Application Development
                                    </Card.Title>
                                    <Card.Text className={`sora font-size-14 font-400 mt-4`}>
                                        One of the fundamental promises we gave ourselves when Code Studio was born, is to always keep pace with the latest innovations and technologies to
                                        provide you with a smooth navigation experience by creating a digital smart interface. Using the latest development technologies and design trends, we thrive to
                                        bring you the most vibrant and upbeat mobile applications
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
