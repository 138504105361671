import React, { useEffect, useState } from "react";
import NewsNavigation from "../../Components/News/Navigation/NewsNavigation";
import ImagefullPage from "../../assets/images/ImagefullPage.png";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { SCREEN_SIZE } from "../../Constants/ScreenSizes";
import { get } from "../../Axios/get";
import {NewsApi, SingleNewsApi, SingleVideosApi, VideosApi} from "../../Axios/EndPoints";
import ItemLoader from "../../Components/Loaders/ItemLoader";
import RightNews from "../../Components/News/RightNews";
import GoogleAd from "../../Components/GoogleAd";
import moment from "moment";
import SiteMetaHelmet from "../../Components/SiteMetaHelmet";
import RecommendedForYouVideos from "../../Components/Videos/RecommendedForYouVideos";
import { useRef } from "react";
import ReactPlayer from "react-player";
import RightNewsVideo from "./RightNewsVideo";

const VideoSingle = (props) => {
  let [isMobile, setIsMobile] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  const [recommendedNews, setRecommendedNews] = useState([]);
  let [news, setNews] = useState(null);
  const { category, slug } = useParams();
  const ref = useRef();

  const fetchSingleNews = () => {
    get(SingleVideosApi + slug)
      .then((response) => {
        console.log();
        setNews(response.data.response);
        setIsLoading(false);
        let url = response.data.response.url;
        // window.open(url, '_self');
      })
      .catch((error) => {
        console.error("singleNewsError", error);
      });
  };

  const fetchRecommendedNews = () => {
    let parameters = "?limit=15&recommended=true";
    get(VideosApi + parameters)
      .then((response) => {
        setRecommendedNews(response.data.response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("latestNewsError", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setIsLoading(true);
    fetchSingleNews();
  }, [category, slug]);

  useEffect(() => {
    fetchRecommendedNews();
  }, []);

  const renderCategoryOfNews = () => {
    return (
      <div className={`container ${isMobile ? "mt-4" : "mt-3"}`}>
        <div className={"row mb-5"}>
          <div
            className={
              "col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
            }
          >
            <Link
              to={"/videos"}
              className={"text-decoration-none text-grey font-size-14"}
            >
              Videos
            </Link>
            <span className={"text-grey font-size-14"}> > </span>
            <Link
              to={`/videos/${category}`}
              className={"text-decoration-none text-grey font-size-14"}
              style={{ textTransform: "capitalize" }}
            >
              {category}
            </Link>
            <span className={"text-grey font-size-14"}> > </span>
            <span className={"text-pink font-size-14"}>{slug}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderNewsContent = () => {
    return (
      <div className={"container"}>
        <GoogleAd id={"9462835128"} />
        <div className={"row mt-3"}>
          <div
            className={
              "col-sm-12 col-xs-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8"
            }
          >
            <ReactPlayer
              url={news?.url}
              width={"100%"}
              height={isMobile ? "175px" : "500px"}
            />
            <div className={`d-flex flex-row justify-content-between mt-3`}>
              <span className={"text-grey mb-3"}>
                {moment(news?.publisher?.published_at).format(
                  "dddd, MMMM Do Y H:m"
                )}
              </span>
              <span>
                <img
                  src={news?.publisher?.icon}
                  alt={"publisher icon"}
                  className={"me-2"}
                  height={25}
                />
                <span className={``}>
                  {news?.publisher?.name || "Code Studio"}
                </span>
              </span>
            </div>
            <div className={"col-12 mt-3"}>
              <span dangerouslySetInnerHTML={{ __html: news?.title }} />
            </div>
            <div className={"col-12 mt-3"}>
              <span dangerouslySetInnerHTML={{ __html: news?.description }} />
              ...
              <a href={news?.url} className={"ms-2"} target={"_blank"}>
                Read More
              </a>
            </div>
            <br />
            <GoogleAd id={"2122836505"} format={"autorelaxed"} />
          </div>
          {!isMobile && <RightNewsVideo />}
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <>
        <SiteMetaHelmet
          title={news?.title}
          image={news?.source_image}
          description={news?.title}
          url={news?.url}
        />
        <div className={`${isMobile ? "" : "container-fluid"}`}>
          <div
            className={`${
              isMobile ? "" : "container-fluid"
            } bg-light-pink-gradient mt-5`}
          >
            <NewsNavigation bg={"light"} where={'videos'} />
            {renderCategoryOfNews()}
            {renderNewsContent()}
          </div>
          <RecommendedForYouVideos
            recommendedNews={recommendedNews}
            isMobile={isMobile}
            ref={ref}
          />
          <GoogleAd id={"9462835128"} />
        </div>
      </>
    );
  };

  return isLoading ? <ItemLoader loading={isLoading} /> : renderMain();
};

export default VideoSingle;
