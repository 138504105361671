import {Container, Nav, Navbar} from "react-bootstrap";
import {NavLink, useLocation} from "react-router-dom";
import {withRouter} from "../CustomFunctions/withRouter";
import {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import darkLogo from "../assets/images/dark_Logo.png";
import lightLogo from "../assets/images/light_Logo.png";

const Navigation = (props) => {
    const location = useLocation();

    const [, setY] = useState(window.scrollY);
    const [navbarColor, setNavbarColor] = useState("transparent");
    const [shadow, setShadow] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
        if (window.scrollY <= 5) {
            setNavbarColor("transparent");
            setShadow("");
        } else {
            setNavbarColor(props.bg);
            setShadow("shadow");
        }
    }, []);

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.innerWidth, isMobile]);

    const renderMain = () => {
        return (
            <Navbar
                collapseOnSelect
                sticky={"top"}
                expand={"lg"}
                bg={navbarColor}
                variant={navbarColor}
                className={`${shadow} ${props.className}`}
            >
                <Container>
                    <Navbar.Brand>
                        <NavLink to={"/"} className={"text-decoration-none"}>
                            <img
                                src={props.bg === "dark" ? lightLogo : darkLogo}
                                alt={"Code Studio"}
                                height={isMobile ? "35" : "50"}
                            />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={"responsive-navbar-nav"}/>
                    <Navbar.Collapse
                        id={"responsive-navbar-nav"}
                        className={`${isMobile ? "shadow" : ""}`}
                    >
                        <Nav
                            className={`float-end justify-content-end w-100`}
                            activeKey={location.pathname}
                        >
                            <Nav.Link
                                as={NavLink}
                                eventKey={"1"}
                                to={"/"}
                                className={`${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                }`}
                                end={true}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link
                                as={NavLink}
                                eventKey={"2"}
                                to={"/about-us"}
                                className={`${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                }`}
                            >
                                About Us
                            </Nav.Link>
                            <Nav.Link
                                as={NavLink}
                                eventKey={"3"}
                                to={"/services"}
                                className={`${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                }`}
                            >
                                Services
                            </Nav.Link>
                            <Nav.Link
                                as={NavLink}
                                eventKey={"4"}
                                to={"/portfolio"}
                                className={`${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                }`}
                            >
                                Portfolio
                            </Nav.Link>
                            <a
                                href="/news"
                                eventKey={"5"}
                                className={`nav-link ${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                } ${location.pathname.includes("/news") ? "active" : ""}`}
                            >
                                News
                            </a>
                            <a
                                href="/videos"
                                eventKey={"6"}
                                className={`nav-link ${
                                    props.bg === "dark" ? "text-white" : "nav-link"
                                } ${location.pathname.includes("/videos") ? "active" : ""}`}
                            >
                                Videos
                            </a>
                            <Nav.Link
                                as={NavLink}
                                eventKey={"8"}
                                to={"/contact-us"}
                                className={"btn btn-violet rounded-0 ms-4"}
                            >
                                Contact Us
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    };

    const renderNews = () => {
        return (
            <Navbar
                collapseOnSelect
                sticky={"top"}
                expand={"lg"}
                bg={navbarColor}
                variant={navbarColor}
                className={`${shadow} ${props.className}`}
            >
                <Container>
                    <Navbar.Brand>
                        <NavLink to={"/"} className={"text-decoration-none"}>
                            <img
                                src={props.bg === "dark" ? lightLogo : darkLogo}
                                alt={"Code Studio"}
                                height={isMobile ? "35" : "50"}
                            />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={"responsive-navbar-nav"}/>
                    <Navbar.Collapse
                        id={"responsive-navbar-nav"}
                        className={`${isMobile ? "shadow" : ""}`}
                    >
                        <Nav
                            className={`float-end justify-content-end w-100 ${location.pathname.includes("/news") ? "active" : ""}`}
                            activeKey={location.pathname}
                        >
                            <Nav.Link
                                as={NavLink}
                                eventKey={"1"}
                                to={"/"}
                                className={`${
                                    props.bg === "dark"
                                        ? "text-white pipe-white"
                                        : "nav-link pipe-black"
                                } ${location.pathname.includes("/news") ? "active" : ""}`}
                                end={true}
                            >
                                Home
                            </Nav.Link>
                            <a
                                href="/videos"
                                eventKey={"6"}
                                className={`nav-link ${
                                    props.bg === "dark" ? "text-white" : "nav-link"
                                } ${location.pathname.includes("/videos") ? "active" : ""}`}
                            >
                                Videos
                            </a>
                            {/*<Nav.Link as={NavLink} eventKey={'5'} to={'/blog'} className={`${props.bg === 'dark' ? 'text-white' : 'nav-link'}`}>Blog</Nav.Link>*/}
                            <Nav.Link
                                as={NavLink}
                                eventKey={"8"}
                                to={"/contact-us"}
                                className={"btn btn-violet rounded-0 ms-4"}
                            >
                                Contact Us
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    };

    const render = () => {
        if (window.location.hostname === '127.0.0.1' || window.location.hostname == 'codestudionews.com' || window.location.hostname == 'www.codestudionews.com') {
            return renderNews();
        }

        return renderMain();
    };

    return render();
};

export default withRouter(Navigation);

Navigation.propTypes = {
    bg: PropTypes.string.isRequired,
};
