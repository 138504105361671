import PurpleRightArrow from "./PurpleRightArrow";

const NumberOfPackagePoints = (props) => {
  const {
    point,
    variant,
    darkBg,
    isMobile,
    thirdPackage,
    firstPackage,
    secondPackage,
  } = props;

  const renderMain = () => {
    return (
      <div className={"d-flex flex-column w-100 "}>
        <hr
          className={`customHR ${thirdPackage ? "third-hr-gradient" : null} 
          ${firstPackage ? "first-hr-gradient" : null}
          ${secondPackage ? "second-hr-gradient" : null}
          ms-4`}
          style={{ width: "90%" }}
        />
        <span
          className={`${isMobile ? "font-size-10" : "font-size-13"} font-600 ${
            thirdPackage ? "align-self-end" : null
          }  ${isMobile ? "mb-1" : null}`}
          style={{
            color: darkBg ? "white" : "black",
            marginLeft: thirdPackage ? null : "40px",
            marginRight: thirdPackage ? "35px" : null,
          }}
        >
          {point}
        </span>
      </div>
    );
  };

  return renderMain();
};

export default NumberOfPackagePoints;
