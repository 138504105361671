import {Link} from "react-router-dom";

export const PageNotFound = () => {
    return (
        <div className={`container mt-5 pt-5 mb-5 pb-5`}>
            <div className={`row`}>
                <div className={`col-12 text-center`}>
                    <div className={`bebas font-500 font-size-70 text-decoration-underline text-danger`}>
                        404!
                    </div>
                    <div className={`bebas font-500 font-size-70`}>
                        Oops!
                    </div>
                    <div className={`sora font-500 font-size-30`}>
                        The page you are looking for might not exist!
                    </div>
                    <div>
                        <Link to={'/'} className={`sora btn btn-violet btn-lg mt-5`}>
                            Click here to return to home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}