import React, {useEffect, useRef, useState} from 'react';
import NewsCard from "../NewsCard";
import Slider from "react-slick";
import {SCREEN_SIZE} from "../../../Constants/ScreenSizes";
import {get} from "../../../Axios/get";
import {NewsApi} from "../../../Axios/EndPoints";

const RecommendedForYou = () => {
    let [isMobile, setIsMobile] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [recommendedNews, setRecommendedNews] = useState([]);

    const fetchRecommendedNews = () => {
        let parameters = '?limit=15&recommended=true';
        get(NewsApi + parameters)
            .then((response) => {
                setRecommendedNews(response.data.response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('latestNewsError', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        fetchRecommendedNews();
    }, []);

    const ref = useRef()

    useEffect(() => {
    }, [ref]);

    const settings = {
        infinite: false,
        dots: false,
        draggable: true,
        loop: false,
        slidesToShow: 3,
        cssEase: 'linear',
        arrows: true,
        slidesToScroll: 1,
        autoplay: false,
        useCSS: true,
        className: "slick-track ",
        variableWidth: true,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    centerMode: false,
                    centerPadding: 10,
                    infinite: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false,
                    scrollable: true,
                    draggable: true,
                    swipeToSlide: true,
                    autoplay: false,
                    useCSS: true,
                    loop: false,
                }
            }
        ],
    };

    const recommendedForYouHeader = () => {
        return (<div className={'row pt-5'}>
            <div className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 '}>
                <div className={'d-flex justify-content-between mb-3'}>
                    <div className={`col-sm-12 col-xs-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 align-items-center ${isMobile && 'ms-2'}`}>
                       <span className={`${isMobile ? "font-size-24" : "font-size-30"} ${isMobile && "ms-2"} text-dark-blue bebas font-500 text-decoration-underline`}>Recommended for You</span>
                    </div>
                </div>
            </div>
        </div>)
    }

    const renderRecommendedForYou = () => {
        return (
            <div className={`${isMobile ? "" : "container-fluid"} bg-light-pink mb-5 pb-5`}>
                <div className={`${isMobile ? "" : "container"} mt-5`}>
                    {recommendedForYouHeader()}
                    <div className={`row ${isMobile && 'ms-auto'} mb-3`}>
                        <Slider ref={ref}  {...settings}>
                            {recommendedNews.map((value, index) => (
                                <NewsCard
                                    news={value}
                                    key={index}
                                    isMobile={isMobile}
                                    isNewsBelow={true}
                                    isLargeImage={true}
                                    color={'text-dark-blue'}
                                    newsCard={"img-fluid"}
                                    height={'250px'}
                                />
                            ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>

        )
    }

    const renderMain = () => {
        return (
            <>
                {
                    renderRecommendedForYou()
                }
            </>
        )
    }

    return renderMain();
};

export default RecommendedForYou;
