import './../assets/css/index.css';
import {BannerComponent} from "../Components/Services/BannerComponent";
import {ServicesComponent} from "../Components/Services/ServicesComponent";
import {FAQsComponent} from "../Components/FAQsComponent";

export const Services = () => {

    const render = () => {
        return (
            <div className={`col`}>
                <BannerComponent/>
                <div className={`bg-light-pink pt-5 pb-5`}>
                    <ServicesComponent/>
                </div>
                <FAQsComponent/>
            </div>
        )
    }

    return render();
}