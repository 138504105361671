import {FAQSingleComponent} from "./FAQSingleComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import {FAQ_API} from "../Axios/EndPoints";
import {GetQuoteButton} from "./GetQuoteButton";


export const FAQsComponent = () => {

    const [faqs, setFaqs] = useState([]);

    const fetchFaqs = () => {
        axios
            .get(`${FAQ_API}`)
            .then((res) => {
                setFaqs(res.data.response);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchFaqs();
    }, []);

    const render = () => {
        return (
            <div className={`container mt-5 pt-5 pb-5`}>
                <div className={`row`}>
                    <div className={`col-xl-3 col-lg-3 col-md-3 col-sm-12`}>
                        <div className={`bebas font-500 font-size-40 text-decoration-underline`}>
                            Frequently Asked Questions
                        </div>
                        <div className={`mt-5 pt-4`}>
                            <GetQuoteButton/>
                        </div>
                    </div>
                    <div className={`col-xl-9 col-lg-9 col-md-9 col-sm-12`}>
                        {
                            faqs?.map((data, index) => (
                                <FAQSingleComponent faq={data} key={`faq_main_${index}`}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    return render();
}
