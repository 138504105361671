export const MissionVisionComponent = () => {
    return (
        <div className={`container mt-5 pt-5 pb-5 p-lr-20`}>
            <div className={`row`}>
                <div className={`col-xl-8 col-lg-8 col-md-8 col-sm-12 d-flex flex-column justify-content-center`}>
                    <div className={`sora font-400 font-size-12 text-pink mb-4`}>
                        Our Mission
                    </div>
                    <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                        Inspire, Innovate, Share
                    </div>
                    <div className={`sora`}>
                        <br/>
                        At Code Studio, our core principles are to make websites, applications and to give services which enables people and businesses around the world to utilise their full potential.
                    </div>
                </div>
                <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 text-end`}>
                    <img src={require('../../assets/images/resized/Inspire,-Innovate,-Share.png')} alt={'About Code Studio'} className={`img-fluid`}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 text-start`}>
                    <img src={require('../../assets/images/resized/Laser-Focus.png')} alt={'About Code Studio'} className={`img-fluid`}/>
                </div>
                <div className={`col-xl-8 col-lg-8 col-md-8 col-sm-12 d-flex flex-column justify-content-center`}>
                    <div className={`sora font-400 font-size-12 text-pink mb-4`}>
                        Our Vision
                    </div>
                    <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                        HIGHLY FOCUSED
                    </div>
                    <div className={`sora`}>
                        <br/>
                        By focusing on performance driven employees who have a result oriented mindset and challenging ourselves to the limit, at Code Studio we have developed a quest for success through our innovative ways.
                    </div>
                </div>
            </div>
        </div>
    )
}
