import React from 'react'
import Card from 'react-bootstrap/Card';
import mission from '../../assets/images/mission.png'


const BlogPosts = () => {
    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center pt-5'}>
                    <span className='bebas font-size-50 font-600 text-decoration-underline'>
                        OUR BLOG
                    </span>
                </div>
            </div>

            <div className={'row mt-5'}>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
            </div>

            <div className={'row mt-5'}>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-5 pb-5'}>
                    <Card className='d-flex flex-column border-0'>
                        <Card.Img variant="top" src={mission}
                            style={{
                                height: "285px",
                                objectFit: "cover"
                            }}
                        />
                        <Card.Text className={'mt-3'}>29 Sep 2022</Card.Text>
                        <Card.Title>Wealthiest Americans including Jeff Bezos and Elon Musk Lose $93Bn After Inflation-led Market </Card.Title>
                        <Card.Text>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</Card.Text>
                        <div className={'mt-4'}>
                            <button className={'btn btn-violet rounded-0 bebas font-600 font-size-30'}>READ MORE</button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default BlogPosts