import {GetQuoteButton} from "../GetQuoteButton";

export const BannerComponent = () => {
    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <div className={'banner-text'}>
                    <div className={`container mt-5 pt-5 pb-5 p-lr-20`}>
                        <div className={`row`}>
                            <div className={`col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center`}>
                                <div className={`sora font-400 font-size-12 text-pink mb-4`}>
                                    Services
                                </div>
                                <div className={`bebas font-500 font-size-30 text-decoration-underline`}>
                                    WE OFFER A VARIETY OF CREATIVE SERVICES TO SUITE YOUR EVERY NEED
                                </div>
                                <div className={`sora`}>
                                    <br/>
                                    We are a one stop solution for all your digital, website and application development. We offer all the products which are the solution to bring your innovative concept to the global and local market. We are confident in our service having served countless well renowned clients. Our services are but not limited to:
                                    <br/>
                                    <br/>
                                    <ul className={``}>
                                        <li>Web design and web development</li>
                                        <li>Software development</li>
                                        <li>Standalone and cloud service deployments</li>
                                        <li>Database design and development</li>
                                        <li>Mobile application development</li>
                                        <li>Graphic design and Branding</li>
                                        <li>Digital marketing</li>
                                        <li>Domain and Hosting management</li>
                                        <li>SEO</li>
                                    </ul>

                                    <p>
                                        Our mission is simple: Make technology an asset for your business not a problem.
                                    </p>
                                </div>
                                <div className={``}>
                                    <GetQuoteButton/>
                                </div>
                            </div>
                            <div className={`offset-xl-2 offset-lg-2 offset-md-2 offset-sm-9 col-xl-3 col-lg-3 col-md-3 col-sm-12 text-end`}>
                                <img src={require('../../assets/images/resized/Variety---Services.png')} alt={'About Code Studio'} className={`img-fluid`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}