import React from 'react';
import Slider from "react-slick";
import VideoCard from "./VideoCard";
import GoogleAd from "../GoogleAd";

const RecommendedForYouVideos = (props) => {
	const {recommendedNews, isMobile, ref} = props;

	const settings = {
		className: "slick-arrow",
		infinite: false,
		dots: false,
		draggable: true,
		loop: false,
		slidesToShow: 4,
		cssEase: 'linear',
		arrows: true,
		slidesToScroll: 1,
		autoplay: false,
		useCSS: true,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					className: "slick-arrow",
					centerMode: false,
					centerPadding: 10,
					infinite: false,
					slidesToScroll: 1,
					slidesToShow: 1,
					arrows: false,
					scrollable: true,
					draggable: true,
					swipeToSlide: true,
					autoplay: false,
					useCSS: true,
					loop: false,
				}
			}
		],
	};

	const recommendedForYouHeader = () => {
		return (<div className={'row pt-5'}>
			<div className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 '}>
				<div className={'d-flex justify-content-between mb-3'}>
					<div className={`col-sm-12 col-xs-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 align-items-center ${isMobile && 'ms-2'}`}>
						<span className={`${isMobile ? "font-size-24" : "font-size-30"} ${isMobile && "ms-2"} bebas font-500  text-pink bebas`}>Recommended for You</span>
					</div>
				</div>
			</div>
		</div>)
	}

	const renderRecommendedForYou = () => {
		return (
			<>
				<div className={`${isMobile ? "" : "container-fluid"} bg-light-pink mb-5 pb-5`}>
					<div className={`${isMobile ? "" : "container"} mt-5`}>
						{recommendedForYouHeader()}
						<div className={`row ${isMobile && 'ms-auto'} mb-3 `}>
							<Slider ref={ref}  {...settings}>
								{recommendedNews.map((value, index) => (
									<VideoCard
										news={value}
										key={index}
										isMobile={isMobile}
										isNewsBelow={true}
										color={'text-dark-blue'}
										newsCard={"img-fluid"}
										height={'250px'}
										recommendedVideos={true}
									/>
								))
								}
							</Slider>
						</div>
					</div>
				</div>
				<GoogleAd id={'9462835128'}/>
			</>

		)
	}

	const renderMain = () => {
		return (
			<>
				{
					renderRecommendedForYou()
				}
			</>
		)
	}

	return renderMain();
};

export default RecommendedForYouVideos;
